import { Observer } from "mobx-react";
import { Button, Col, Popconfirm, Row } from "antd";
import React, { useEffect, useState } from "react";
import {
  LeftOutlined,
  SearchOutlined,
  PlusOutlined,
  ArrowUpOutlined,
  EyeOutlined,
  DeleteOutlined,
  EditOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { useMainStore } from "../../../store/mainStore";
import { debounce } from "lodash";
import { createGlobalStyle } from "styled-components";

const ActivityList = (props) => {
  const { ViewShowDocDrawer, EditShowTypeOneDrawer, confirm, cancel, isOpen } =
    props;
  const [loading, setLoading] = useState(true);
  const mainStore = useMainStore();
  const { disorderStore, activityStore } = mainStore;
  const { getMaterial, singleDisorderData, activityMaterial } = disorderStore;
  const { setMaterialID } = activityStore;

  // const getMaterialList = debounce(async () => {
  const getMaterialList = async () => {
  
  const [{ _id }] = singleDisorderData;
    console.log("_id in list", _id, singleDisorderData);

    if (!_id) return;

    const newBody = {
      disorderId: _id,
      materialType: "ACTIVITY",
    };
    setLoading(true); // Start loading before the data fetch
    await getMaterial(newBody).then((res) => {
      console.log("res-activity", res.data);
      setMaterialID(res.data.length !== 0 ? res.data[0]["_id"] : "");
      setLoading(false);
    });
  }
  // }, 1000);

  useEffect(() => {
    getMaterialList();
    console.log("single-disorder-data : ", singleDisorderData);
    console.log("res activity activityMaterial", activityMaterial);
  }, [singleDisorderData]);

  return (
    <Observer>
      {() => {
        const {
          activityMaterial,
        } = disorderStore;
      return(
        <>
        {/* {loading ? (
          <p>Loading activities...</p>
        ) : (

        )} */}
        {activityMaterial?.length > 0 && (
            <Row gutter={24}>
              <Col span={24}>
                {activityMaterial?.map((item) => {
                  return (
                    <>
                      {item?.activities?.map((activity) => {
                        return (
                          <div className="materials_inner_card">
                            <div className="content">
                              <h3 className="title">
                                {activity?.question ?? ""}
                              </h3>
                              <p className="para">
                                {activity?.rightAnswer ?? ""}
                              </p>
                            </div>
                            <div className="dashboard_action">
                              {/* <Button
                                className="action_btn view mr_5"
                                onClick={ViewShowDocDrawer}
                              >
                                <PlayCircleOutlined />
                              </Button> */}
                              <Button
                                type="primary"
                                className="action_btn mr_5"
                                // onClick={EditShowTypeOneDrawer}
                                onClick={() =>
                                  EditShowTypeOneDrawer(item, activity._id)
                                }
                              >
                                <EditOutlined />
                              </Button>
                              {/* <Button className="action_btn delete mr_5">
                                <Popconfirm
                                  title="Delete the task"
                                  description="Are you sure to delete this task?"
                                  onConfirm={confirm}
                                  onCancel={cancel}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <DeleteOutlined />
                                </Popconfirm>
                              </Button> */}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  );
                })}
              </Col>
              {/* <Col span={12}>
            //     <div className="materials_inner_card">
            //       <div className="content">
            //         <h3 className="title">Day 2</h3>
            //         <p className="para">Lorem Ipsum is simply dummy text.</p>
            //       </div>
            //       <div className="dashboard_action">
            //         <Button
            //           className="action_btn view mr_5"
            //           onClick={ViewShowDocDrawer}
            //         >
            //           <PlayCircleOutlined />
            //         </Button>
            //         <Button
            //           type="primary"
            //           className="action_btn mr_5"
            //           onClick={EditShowTypeTwoDrawer}
            //         >
            //           <EditOutlined />
            //         </Button>
            //         <Button className="action_btn delete mr_5">
            //           <Popconfirm
            //             title="Delete the task"
            //             description="Are you sure to delete this task?"
            //             onConfirm={confirm}
            //             onCancel={cancel}
            //             okText="Yes"
            //             cancelText="No"
            //           >
            //             <DeleteOutlined />
            //           </Popconfirm>
            //         </Button>
            //       </div>
            //     </div>
            //   </Col>
            //   <Col span={12}>
            //     <div className="materials_inner_card">
            //       <div className="content">
            //         <h3 className="title">Day 3</h3>
            //         <p className="para">Lorem Ipsum is simply dummy text.</p>
            //       </div>
            //       <div className="dashboard_action">
            //         <Button
            //           className="action_btn view mr_5"
            //           onClick={ViewShowDocDrawer}
            //         >
            //           <PlayCircleOutlined />
            //         </Button>
            //         <Button
            //           type="primary"
            //           className="action_btn mr_5"
            //           onClick={EditShowTypeThreeDrawer}
            //         >
            //           <EditOutlined />
            //         </Button>
            //         <Button className="action_btn delete mr_5">
            //           <Popconfirm
            //             title="Delete the task"
            //             description="Are you sure to delete this task?"
            //             onConfirm={confirm}
            //             onCancel={cancel}
            //             okText="Yes"
            //             cancelText="No"
            //           >
            //             <DeleteOutlined />
            //           </Popconfirm>
            //         </Button>
            //       </div>
            //     </div>
            //   </Col>
            //   <Col span={12}>
            //     <div className="materials_inner_card">
            //       <div className="content">
            //         <h3 className="title">Day 4</h3>
            //         <p className="para">Lorem Ipsum is simply dummy text.</p>
            //       </div>
            //       <div className="dashboard_action">
            //         <Button
            //           className="action_btn view mr_5"
            //           onClick={ViewShowDocDrawer}
            //         >
            //           <PlayCircleOutlined />
            //         </Button>
            //         <Button
            //           type="primary"
            //           className="action_btn mr_5"
            //           onClick={EditShowTypeFourDrawer}
            //         >
            //           <EditOutlined />
            //         </Button>
            //         <Button className="action_btn delete mr_5">
            //           <Popconfirm
            //             title="Delete the task"
            //             description="Are you sure to delete this task?"
            //             onConfirm={confirm}
            //             onCancel={cancel}
            //             okText="Yes"
            //             cancelText="No"
            //           >
            //             <DeleteOutlined />
            //           </Popconfirm>
            //         </Button>
            //       </div>
            //     </div>
            //   </Col>
            //   <Col span={12}>
            //     <div className="materials_inner_card">
            //       <div className="content">
            //         <h3 className="title">Day 5</h3>
            //         <p className="para">Lorem Ipsum is simply dummy text.</p>
            //       </div>
            //       <div className="dashboard_action">
            //         <Button
            //           className="action_btn view mr_5"
            //           onClick={ViewShowDocDrawer}
            //         >
            //           <PlayCircleOutlined />
            //         </Button>
            //         <Button
            //           type="primary"
            //           className="action_btn mr_5"
            //           onClick={EditShowTypeFiveDrawer}
            //         >
            //           <EditOutlined />
            //         </Button>
            //         <Button className="action_btn delete mr_5">
            //           <Popconfirm
            //             title="Delete the task"
            //             description="Are you sure to delete this task?"
            //             onConfirm={confirm}
            //             onCancel={cancel}
            //             okText="Yes"
            //             cancelText="No"
            //           >
            //             <DeleteOutlined />
            //           </Popconfirm>
            //         </Button>
            //       </div>
            //     </div>
            //   </Col>
            //   <Col span={12}>
            //     <div className="materials_inner_card">
            //       <div className="content">
            //         <h3 className="title">Day 6</h3>
            //         <p className="para">Lorem Ipsum is simply dummy text.</p>
            //       </div>
            //       <div className="dashboard_action">
            //         <Button
            //           className="action_btn view mr_5"
            //           onClick={ViewShowDocDrawer}
            //         >
            //           <PlayCircleOutlined />
            //         </Button>
            //         <Button
            //           type="primary"
            //           className="action_btn mr_5"
            //           onClick={EditShowTypeSixDrawer}
            //         >
            //           <EditOutlined />
            //         </Button>
            //         <Button className="action_btn delete mr_5">
            //           <Popconfirm
            //             title="Delete the task"
            //             description="Are you sure to delete this task?"
            //             onConfirm={confirm}
            //             onCancel={cancel}
            //             okText="Yes"
            //             cancelText="No"
            //           >
            //             <DeleteOutlined />
            //           </Popconfirm>
            //         </Button>
            //       </div>
            //     </div>
            //   </Col>
            //   <Col span={12}>
            //     <div className="materials_inner_card">
            //       <div className="content">
            //         <h3 className="title">Day 6</h3>
            //         <p className="para">Lorem Ipsum is simply dummy text.</p>
            //       </div>
            //       <div className="dashboard_action">
            //         <Button
            //           className="action_btn view mr_5"
            //           onClick={ViewShowDocDrawer}
            //         >
            //           <PlayCircleOutlined />
            //         </Button>
            //         <Button
            //           type="primary"
            //           className="action_btn mr_5"
            //           onClick={EditShowTypeSevenDrawer}
            //         >
            //           <EditOutlined />
            //         </Button>
            //         <Button className="action_btn delete mr_5">
            //           <Popconfirm
            //             title="Delete the task"
            //             description="Are you sure to delete this task?"
            //             onConfirm={confirm}
            //             onCancel={cancel}
            //             okText="Yes"
            //             cancelText="No"
            //           >
            //             <DeleteOutlined />
            //           </Popconfirm>
            //         </Button>
            //       </div>
            //     </div>
            //   </Col> */}
            </Row>
          )}
      </>
      )
      }}
    </Observer>

  );
};

export default ActivityList;
