import { Flex, Rate } from "antd";
import React, { useState } from "react";

const Rating = () => {
  const [value, setValue] = useState(3);
  return (
    <Flex gap="middle" vertical>
      <Rate tooltips={"test"} onChange={setValue} value={value} />
    </Flex>
  );
};

export default Rating;
