import React, { useEffect, useState } from "react";
import { Content, Header } from "antd/es/layout/layout";
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Drawer,
  Dropdown,
  Form,
  Image,
  Input,
  Layout,
  Menu,
  Popconfirm,
  Row,
  Select,
  Table,
  message,
} from "antd";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/logo.png";
import AvatarImg from "../../assets/avatar.svg";
import Sider from "antd/es/layout/Sider";
import {
  BiSolidCloudUpload,
  BiSolidDollarCircle,
  BiSolidLike,
  BiSolidUserAccount,
  BiSolidMessageSquareError
} from "react-icons/bi";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  LeftOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  PlusOutlined,
} from "@ant-design/icons";

const AuthLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedMenuItem, setMenuItem] = useState("/users");
  const navigate = useNavigate();
  const {pathname} = useLocation();


  useEffect(() => {
    if (pathname.startsWith("/users")) {
      setMenuItem("/users");
    } else if (pathname.includes("/slp-user-clinicians-subscription")) {
      setMenuItem("/slp-user-clinicians-subscription");
    } else if (pathname.includes("/caretaker-parents-consumer")) {
      setMenuItem("/caretaker-parents-consumer");
    } else if (pathname.startsWith("/materials")) {
      setMenuItem("/materials");
    }else if (pathname.startsWith("/feedbacks")) {
      setMenuItem("/feedbacks");
    }else if (pathname.startsWith("/faq")) {
      setMenuItem("/faq");
    }
  }, [navigate]);

  const items = [
    {
      key: "1",
      label: <Link to="/forgot-password">Forgot Password</Link>,
    },
    {
      key: "2",
      label: <Link to={"/logout"}>Logout</Link>,
    },
  ];
  return (
    <div>
      <Layout style={{ width: "100%", height: "100%" }}>
        <Sider
          style={{
            backgroundColor: "#35A8DF",
            height: "auto",
            // borderRadius: 16,
            marginTop: -10,
            marginLeft: -10,
            marginBottom: -10,
          }}
          trigger={null}
          collapsible
          collapsed={collapsed}
        >
          {!collapsed ? (
            <Image
              width={180}
              src={Logo}
              alt="Logo"
              preview={false}
              style={{
                margin: 10,
                padding: 10,
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          ) : (
            <Image
              width={70}
              src={Logo}
              alt="Logo"
              preview={false}
              style={{
                margin: 10,
                padding: 10,
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          )}
          <Menu
            style={{
              // backgroundColor: "#35A8DF",
              // color: "#fff",
              height: "100vh",
            }}
            mode="inline"
            theme="dark"
            defaultSelectedKeys={["/users"]}
            onClick={(item) => {
              navigate(item.key);
            }}
            selectedKeys={[selectedMenuItem]}
            items={[
              // {
              //   key: "/dashboard",
              //   icon: <BiHomeAlt2 />,
              //   label: "Dashboard",
              // },
              // {
              //   key: "/materials",
              //   icon: <FaUserSecret />,
              //   label: "Materials / Clinical resources management",
              // },

              {
                key: "/users",
                icon: <BiSolidUserAccount />,
                label: "User management",
              },
              {
                key: "subscription",
                label: "Subscription",
                icon: <BiSolidDollarCircle />,
                children: [
                  {
                    key: "/slp-user-clinicians-subscription",
                    label: "Clinician",
                  },
                  {
                    key: "/caretaker-parents-consumer",
                    label: "Patient",
                  },
                ],
              },
              {
                key: "/materials",
                icon: <BiSolidCloudUpload />,
                label: "Materials Management",
              },
              {
                key: "/faq",
                icon: <BiSolidMessageSquareError />,
                label: "FAQ",
              },
              {
                key: "/feedbacks",
                icon: <BiSolidLike />,
                label: "Feedbacks",
              },
              
              // {
              //   key: "/",
              //   icon: <FaUsers />,
              //   label: "Activity/Tasks manager",
              // },
              // {
              //   key: "/reports",
              //   icon: <FaUsers />,
              //   label: "Reports",
              // },
              // {
              //   key: "/kpiconfig",
              //   icon: <RiCustomerService2Line />,
              //   label: "KPI configuration",
              // },
              // {
              //   key: "/settings",
              //   icon: <FaTruckFast />,
              //   label: "Profile Settings",
              // },
            ]}
          />
        </Sider>
        <Layout>
          <Header
            style={{
              padding: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              backgroundColor: "#fff",
            }}
          >
            <Button
              type="text"
              icon={collapsed ? <GiHamburgerMenu /> : <LeftOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />
            <div>
              <Dropdown
                menu={{
                  items,
                }}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Avatar
                    src={<img src={AvatarImg} alt="avatar" />}
                    shape="square"
                    size="large"
                  />
                </a>
              </Dropdown>
            </div>
          </Header>

          <Content
            style={{
              backgroundColor: "#F1F5FF",
              padding: 20,
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default AuthLayout;
