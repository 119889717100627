import { Button, message, Popconfirm, Table } from "antd";
import React from "react";
import {
  LeftOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  PlusOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import Rating from "../common/field/Rating";

const FeedBackList = (props) => {
  const { ViewShowDrawer, setViewOpen } = props;
//^1.4.3
//"scripts": {
//   "start": "set PORT=3006 && react-scripts start",
//   "build": "react-scripts build",
//   "test": "react-scripts test",
//   "eject": "react-scripts eject"
// },
//node-sass
  const userData = [
    {
      id: 1,
      name: "Kishan",
      email: "kishan@gmail.com",
      createdAt: "12/03/2024",
      rating: "5",
      feedback: "This is the custom feedback",
    },
    {
      id: 2,
      name: "Dhaval",
      email: "dhaval@gmail.com",
      createdAt: "12/03/2024",
      rating: "4",
      feedback: "This is the custom feedbacks",
    },
    {
      id: 3,
      name: "Rahul",
      email: "rahul@gmail.com",
      createdAt: "12/03/2024",
      rating: "3",
      feedback: "This is the custom feedback",
    },
  ];
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email ID",
      dataIndex: "email",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      render: (_, record) => {
        return <span>15/03/2024</span>;
      },
    },
    {
      title: "Ratings",
      dataIndex: "rating",
      render: (_, record) => {
        return <Rating />;
      },
    },
    {
      title: "Feedbacks",
      dataIndex: "feedback",
      // render: (_, record) => <Rating />,
    },

    {
      title: "Action",
      render: (_, record) => (
        <>
          <div className="dashboard_action">
            <Button
              className="action_btn view mr_5"
              onClick={() => {
                ViewShowDrawer(record.id, [() => setViewOpen(true)]);
              }}
            >
              <EyeOutlined />
            </Button>
            {/* <Popconfirm
              title="Delete the task"
              description="Are you sure to delete this task?"
              onConfirm={() => {
                // confirm(record._id)
              }}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button className="action_btn delete mr_5">
                <DeleteOutlined />
              </Button>
            </Popconfirm> */}
          </div>
        </>
      ),
    },
  ];

  const cancel = (e) => {
    console.log(e);
    message.error("Click on No");
  };

  return (
    <Table
      rowKey={(data) => data?._id ?? 0}
      columns={columns}
      dataSource={userData}
      scroll={{
        x: 800,
      }}
    />
  );
};

export default FeedBackList;
