import { Content, Header } from "antd/es/layout/layout";
import {
  Button,
  Image,
  Layout,
  Menu,
  Collapse,
  theme,
  Row,
  Col,
  Card,
  Statistic,
  Dropdown,
  Avatar,
  Input,
  Select,
  Form,
  Drawer,
  Popconfirm,
  message,
  Checkbox,
  Radio,
  Upload,
  Divider,
  Space,
  Spin,
  Flex,
} from "antd";
import {
  LeftOutlined,
  SearchOutlined,
  PlusOutlined,
  ArrowUpOutlined,
  EyeOutlined,
  DeleteOutlined,
  EditOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  BiSolidUserAccount,
  BiSolidDollarCircle,
  BiSolidCloudUpload,
  BiSolidLike,
  BiSolidMessageSquareError,
} from "react-icons/bi";
import Sider from "antd/es/layout/Sider";
import Logo from "../assets/logo.png";
import AvatarImg from "../assets/avatar.svg";
import React, { useEffect, useRef, useState, useTransition } from "react";
import { Link, useNavigate } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import { Observer } from "mobx-react";
import { useMainStore } from "../store/mainStore";
import { debounce } from "lodash";
import { sendNotification } from "../utils/utilities";
import AddNewVideoDrawer from "../components/videoMaterials/AddNewVideoDrawer";
import VideoMaterialDrawer from "../components/videoMaterials/VideoMaterialDrawer";
import VIewVideoMaterial from "../components/videoMaterials/VIewVideoMaterial";
import DocumentMaterialDrawer from "../components/documentMaterials/DocumentMaterialDrawer";
import AddNewDocumentDrawer from "../components/documentMaterials/AddNewDocumentDrawer";
import ViewDocumentMaterial from "../components/documentMaterials/ViewDocumentMaterial";
import EditNewDocumentDrawer from "../components/documentMaterials/EditNewDocumentDrawer";
import { DisordersIcon, MaterialsIcon } from "../components/icons";
import ActivityList from "../components/material/activity/ActivityList";

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const Materials = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [isPending, startTransition] = useTransition();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [addActivityForm] = Form.useForm();
  const [addMoreForm] = Form.useForm();
  const [editDisorderForm] = Form.useForm();
  const [editActivityForm] = Form.useForm();
  const [uploadStatus , setUploadStatus] = useState("both");

  const mainStore = useMainStore();
  const { disorderStore, activityStore } = mainStore;
  const {setActivityMaterial} = disorderStore;
  
  useEffect(() => {
    debounce(async () => {
      const body = {
        id: "",
        searchQuery: disorderStore.disorderSearchQuery,
      };

      await disorderStore.getAllDisorderList(body);
    }, 1000)();
  }, [disorderStore.disorderSearchQuery]);

  const { token } = theme.useToken();

  const panelStyle = {
    marginBottom: 24,
    background: "#ffffff",
    borderRadius: token.borderRadiusLG,
    border: "none",
  };

  const handleVideoMaterial = (data) => {
    disorderStore.setSingleDisorderData([data]);
    disorderStore.setVideoMaterialModalStatus(true);
  };

  const handleDocumentMaterial = (data) => {
    disorderStore.setSingleDisorderData([data]);
    disorderStore.setDocumentMaterialDrawerStatus(true);
  };
  const handleActivityMaterial = (data) => {
      disorderStore.setSingleDisorderData([data]);
    // disorderStore.setActivityMaterialDrawerStatus(true);
    ShowActivityOneDrawer();
  };

  const itemsData = (data) => {
    return data.map((item) => {
      return {
        key: item._id,
        label: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: 32,
              alignItems: "center",
              justifyContent: "space-between",
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <h1 style={{ fontSize: 18, fontWeight: 400 }}>{item.name}</h1>

            <p style={{ fontSize: 18, fontWeight: 400 }}>
              Total Materials{" "}
              <span style={{ fontWeight: 500 }}>{item.materialsCount}</span>
            </p>
          </div>
        ),
        children: (
          <div style={{ paddingLeft: 20, paddingRight: 20 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h2
                style={{
                  fontWeight: 400,
                  color: "#27A9E0",
                  fontSize: "24px",
                  margin: "0",
                  marginBottom: "150x",
                }}
              >
                {item.name}
              </h2>
              <div>
                <Button
                  type="primary"
                  onClick={() => EditShowDrawer(item._id)}
                  className="action_btn mr_5"
                >
                  <EditOutlined />
                </Button>
                <Button className="action_btn delete">
                  <Popconfirm
                    title="Delete the task"
                    description="Are you sure to delete this task?"
                    onConfirm={() => confirmDisorderDelete(item?._id)}
                    onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <DeleteOutlined />
                  </Popconfirm>
                </Button>
              </div>
            </div>
            <p>{item.description}</p>

            {item.materialType.includes("VIDEO") && (
              <div className="materials_inner_card">
                <div className="content">
                  <h3 className="title">Videos</h3>
                  {/* <p className="para">Lorem Ipsum is simply dummy text.</p> */}
                </div>
                <div className="dashboard_action">
                  <Button
                    className="action_btn view mr_5"
                    onClick={() => handleVideoMaterial(item)}
                  >
                    <EyeOutlined />
                  </Button>
                  <Popconfirm
                    title="Delete the task"
                    description="Are you sure to delete this task?"
                    onConfirm={() => {
                      const idToDelete = item?.materials
                          .find(material => material.materialType === "VIDEO")?._id; 
              
                      if (idToDelete) {
                          confirm(String(idToDelete)); 
                      } else {
                          message.warning("No material found to delete.");
                      }
                  }}
                    onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button className="action_btn delete mr_5">
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            )}

            {item.materialType.includes("DOCUMENT") && (
              <div className="materials_inner_card">
                <div className="content">
                  <h3 className="title">Documents</h3>
                  {/* <p className="para">Lorem Ipsum is simply dummy text.</p> */}
                </div>
                <div className="dashboard_action">
                  <Button
                    className="action_btn view mr_5"
                    // onClick={ViewShowDocDrawer}
                    onClick={() => handleDocumentMaterial(item)}
                  >
                    <EyeOutlined />
                  </Button>
                  <Popconfirm
                    title="Delete the task"
                    description="Are you sure to delete this task?"
                    onConfirm={() => {
                      const idToDelete = item?.materials
                          .find(material => material.materialType === "DOCUMENT")?._id; // Change "VIDEO" to the desired type
              
                      if (idToDelete) {
                          confirm(String(idToDelete)); // Ensure it's passed as a string
                      } else {
                          message.warning("No material found to delete.");
                      }
                  }}
                    onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button className="action_btn delete mr_5">
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            )}

            {item.materialType.includes("ACTIVITY") && (
              
              <div className="materials_inner_card">
                <div className="content">
                  <h3 className="title">Activity</h3>
                  {/* <p className="para">Lorem Ipsum is simply dummy text.</p> */}
                </div>
                <div className="dashboard_action">
                  <Button
                    className="action_btn view mr_5"
                    onClick={() => handleActivityMaterial(item)}
                  >
                    <EyeOutlined />
                  </Button>
                  <Popconfirm
                    title="Delete the task"
                    description="Are you sure to delete this task?"
                    onConfirm={() => {
                      const idToDelete = item?.materials
                          .find(material => material.materialType === "ACTIVITY")?._id;
              
                      if (idToDelete) {
                          confirm(String(idToDelete));
                      } else {
                          message.warning("No material found to delete.");
                      }
                  }}
                    onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button className="action_btn delete mr_5">
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            )}
          </div>
        ),
        showArrow: false,
        style: panelStyle,
      };
    });
  };

  const items = [
    {
      key: "1",
      label: <Link to="/forgot-password">Forgot Password</Link>,
    },
    {
      key: "2",
      label: <Link to={"/logout"}>Logout</Link>,
    },
  ];

  // Delete particular items (Materail) i.e video doc activity
  const confirm = async (id) => {
    console.log(id,typeof id,'idddd')
    try {
        await disorderStore.deleteAMaterial({id:id});
        message.success("Disorder Deleted");

        await disorderStore.getAllDisorderList({
            id: "",
            searchQuery: disorderStore.disorderSearchQuery,
        });
    } catch (error) {
        message.error("Failed to delete disorder: " + (error.message || "An unexpected error occurred"));
        console.error("Delete Disorder Error:", error); 
    }
 };


 //delete disorder
 const confirmDisorderDelete = async (id) => {
  console.log(id,typeof id,'idddd')
  try {
      await disorderStore.deleteADisorder({id:id});
      message.success("Disorder Deleted");

      await disorderStore.getAllDisorderList({
          id: "",
          searchQuery: disorderStore.disorderSearchQuery,
      });
  } catch (error) {
      message.error("Failed to delete disorder: " + (error.message || "An unexpected error occurred"));
      console.error("Delete Disorder Error:", error); 
  }
};
  const cancel = (e) => {
    // message.error("Click on No");
  };

  // Main Add Drawer
  const [addOpen, setAddOpen] = useState(false);
  const AddShowDrawer = () => {
    setAddOpen(true);
  };
  const onAddClose = () => {
    setAddOpen(false);
  };

  // Main Edit Drawer
  const [editOpen, setEditOpen] = useState(false);
  const EditShowDrawer = (id) => {
    const singleDisorder = disorderStore.disorderList.find(
      (item) => item._id === id
    );
    disorderStore.setSingleDisorderData([singleDisorder]);
    disorderStore.setUpdateDisorderModalStatus(true);
    editDisorderForm.setFieldsValue({
      name: singleDisorder?.name,
      description: singleDisorder?.description,
      status: singleDisorder?.status,
      materialType: singleDisorder?.materialType,
    });
    setEditOpen(true);
  };
  const onEditClose = () => {
    disorderStore.setUpdateDisorderModalStatus(false);
  };

  const handleDisorderUpdate = async (values) => {
    const { singleDisorderData } = disorderStore;
    

    const [{ _id }] = singleDisorderData;
    

    const newBody = { id: _id, ...values, status: true };
    console.log(newBody, 'bodyy')

    await disorderStore
      .updateDisorder(newBody)
      .then(async () => {
        message.success("Disorder Updated Successfully");
        onEditClose();
        const body = {
          id: "",
          searchQuery: disorderStore.disorderSearchQuery,
        };

        await disorderStore.getAllDisorderList(body);
      })
      .catch((err) => {
        sendNotification({
          type: "error",
          message: "Something went wrong. Please try again",
        });
      });
  };

  // VideoOne - Drawer
  const [VideoOneOpen, setVideoOneOpen] = useState(false);
  const ShowVideoOneDrawer = () => {
    setVideoOneOpen(true);
  };
  const onVideoOneClose = () => {
    setVideoOneOpen(false);
  };

  // video - View Drawer
  const [viewVideoOpen, setViewVideoOpen] = useState(false);
  const ViewShowVideoDrawer = () => {
    setViewVideoOpen(true);
  };
  const onViewVideoClose = () => {
    setViewVideoOpen(false);
  };

  // Video - Add Drawer
  const [addVideoOpen, setAddVideoOpen] = useState(false);
  const AddShowVideoDrawer = () => {
    setAddVideoOpen(true);
  };
  const onAddVideoClose = () => {
    setAddVideoOpen(false);
  };

  const [value, setValue] = useState(false);
  const onChangeRatio = (e) => {
    const { value } = e.target;
    setValue(value === 1 ? true : false);
  };

  useEffect(() => {
    if(!value){
      editActivityForm.setFieldValue({new_material : ""});
    }
  },[value])

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState([]);
  const [editFileList, setEditFileList] = useState([]);
  // const [editFileList, setEditFileList] = useState([
  //   { uid: '1', name: 'image1.png', status: 'done', url: 'path_to_image1' },
  //   { uid: '2', name: 'image2.png', status: 'done', url: 'path_to_image2' },
  // ]);


  const handlePreview = async (file) => {
    console.log("handle preview called");
    console.log("file : ", file);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  // const handleEditChange = ({ fileList: newFileList }) =>
  //   setEditFileList(newFileList);

  // const handleEditChange = ({ fileList }) => {
  //   // Filter out any placeholders
  //   const filteredFileList = fileList.filter(file => file.url);
  //   setEditFileList(filteredFileList);
  // };

  const handleEditChange = (index, newFileList) => {
    const updatedFileLists = [...editFileList];
    updatedFileLists[index] = newFileList.fileList;
    setEditFileList(updatedFileLists);
  };



  useEffect(() => {
    // Check updated image upload
    const checkUploadStatus = editFileList?.map(fileArray => fileArray[0]?.originFileObj !== undefined);
  
    // Check if all files are updated
    const allImagesUpdated = checkUploadStatus.every(status => status === true);
  
    if (!allImagesUpdated) {
      // Find the index of the first updated image
      const getIndex = checkUploadStatus.findIndex(status => status === true);
      if (getIndex !== -1) {
        // Set upload status based on index
        setUploadStatus(getIndex === 0 ? "first" : "second");
      }
    } else {
      setUploadStatus("both");
    }
  }, [editFileList]);

  useEffect(() => {
    console.log("upload-status : ", uploadStatus);
  },[uploadStatus])
  
    // const handleRemove = (file) => {
    //   setEditFileList((prevList) => prevList.filter(item => item.uid !== file.uid));
    // };

    // const handleRemove = (file) => {
    //   // Remove the file and add a placeholder in its place
    //   setEditFileList(prevList =>
    //     prevList.map((item) => (item.uid === file.uid ? { ...file, url: '' } : item))
    //   );
    // };
    const handleRemove = (file) => {
      setEditFileList(prevList =>
        prevList.map((item) =>
          item.uid === file.uid
            ? { uid: file.uid, name: 'placeholder', url: '', isPlaceholder: true }
            : item
        )
      );
    };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  // DocOne - Drawer
  const [DocOneOpen, setDocOneOpen] = useState(false);
  const ShowDocOneDrawer = () => {
    setDocOneOpen(true);
  };
  const onDocOneClose = () => {
    setDocOneOpen(false);
  };

  // Document - View Drawer
  const [viewDocOpen, setViewDocOpen] = useState(false);
  const ViewShowDocDrawer = () => {
    setViewDocOpen(true);
  };
  const onViewDocClose = () => {
    setViewDocOpen(false);
  };

  // Document - Add Drawer
  const [addDocOpen, setAddDocOpen] = useState(false);
  const AddShowDocDrawer = () => {
    setAddDocOpen(true);
  };
  const onAddDocClose = () => {
    setAddDocOpen(false);
  };

  // Document - edit Drawer
  const [editDocOpen, setEditDocOpen] = useState(false);
  const EditShowDocDrawer = () => {
    setEditDocOpen(true);
  };
  const onEditDocClose = () => {
    setEditDocOpen(false);
  };

  const props = {
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    onChange({ file, fileList }) {
      if (file.status !== "uploading") {
      }
    },
    defaultFileList: [
      {
        uid: "2",
        name: "yyy.png",
        status: "done",
        url: "http://www.baidu.com/yyy.png",
      },
    ],
  };

  // ActivityOne - Drawer
  const [ActivityOneOpen, setActivityOneOpen] = useState(false);
  const ShowActivityOneDrawer = () => {
    setActivityOneOpen(true);
  };
  const onActivityOneClose = () => {
    setActivityMaterial([]);
    setActivityOneOpen(false);
  };

  const [currentValue, setCurrentValue] = useState(0);

  // Activity - Add Drawer
  const [addActivityOpen, setAddActivityOpen] = useState(false);
  const AddShowActivityDrawer = () => {
    const {activityMaterial} = disorderStore;
    console.log("activity-materail : ", activityMaterial);
    // if(activityMaterial.length === 0)
    // setAddActivityOpen(true);
    // else
    // setAddMoreActivityDrawer();
    // const {singleDisorderData} = disorderStore;
    // console.log("single-disorder : ", singleDisorderData);
    // const isActivity = singleDisorderData[0].materials.some((material) => material.materialType === "ACTIVITY")
    // isActivity ? setAddMoreActivityDrawer() : setAddActivityOpen(true);
    console.log("activities : ", activityMaterial?.activities);
    if(activityMaterial.length === 0){
    const {singleDisorderData} = disorderStore;
    console.log("single-disorder : ", singleDisorderData);
    const isActivity = singleDisorderData[0].materials.some((material) => material.materialType === "ACTIVITY")
    isActivity ? setAddMoreActivityDrawer() : setAddActivityOpen(true);
    }
    else{
      if(activityMaterial?.activities?.length === 0){
        setAddActivityOpen(true)
      }
      else{
        setAddMoreActivityDrawer();
      }
    }
    
  };
  const onAddActivityClose = () => {
    setAddActivityOpen(false);
  };

  // Activity - Add More
  const [addMoreActivityOpen, setAddMoreActivityOpen] = useState(false);
  const setAddMoreActivityDrawer = () => {
    setAddMoreActivityOpen(true);
    // setFileList([]);
  };
  const setAddMoreActivityClose = () => {
    setAddMoreActivityOpen(false);
  };

  // Activity - Add More 1
  const [addMoreActivity1Open, setAddMoreActivity1Open] = useState(false);
  const setAddMoreActivityDrawer1 = () => {
    setAddMoreActivity1Open(true);
  };
  const setAddMoreActivityClose1 = () => {
    setAddMoreActivity1Open(false);
  };

  // Activity - Type One
  const [addTypeOneOpen, setAddTypeOneOpen] = useState(false);
  const AddShowTypeOneDrawer = () => {
    setAddTypeOneOpen(true);
  };
  const onAddTypeOneClose = () => {
    addActivityForm.resetFields();
    setFileList([]);
    // setCheckedValue("");
    setAddTypeOneOpen(false);
  };

  // Activity - Type One - Edit
  const [editTypeOneOpen, setEditTypeOneOpen] = useState(false);
  const [editableActivityData, setEditableActivityData] = useState({});
  const [activityId, setActivityId] = useState("");

  const EditShowTypeOneDrawer = (singleActivityData, activityId) => {
    // setAddTypeOneOpen(true);
    console.log("edit-data : ", singleActivityData);
    setEditableActivityData(singleActivityData);
    setActivityId(activityId);
    setEditTypeOneOpen(true);
  };
  console.log(editableActivityData, "editableActivityData");
  const onEditTypeOneClose = () => {
    setEditableActivityData({});
    setEditTypeOneOpen(false);
  };

  // Activity - Type Two
  const [addTypeTwoOpen, setAddTypeTwoOpen] = useState(false);
  const AddShowTypeTwoDrawer = () => {
    setAddTypeTwoOpen(true);
  };
  const onAddTypeTwoClose = () => {
    setAddTypeTwoOpen(false);
  };

  // Activity - Type Two - Edit
  const [editTypeTwoOpen, setEditTypeTwoOpen] = useState(false);
  const EditShowTypeTwoDrawer = () => {
    setEditTypeTwoOpen(true);
  };
  const onEditTypeTwoClose = () => {
    setEditTypeTwoOpen(false);
  };

  // Activity - Type Three
  const [addTypeThreeOpen, setAddTypeThreeOpen] = useState(false);
  const AddShowTypeThreeDrawer = () => {
    setAddTypeThreeOpen(true);
  };
  const onAddTypeThreeClose = () => {
    setAddTypeThreeOpen(false);
  };

  // Activity - Type Three - Edit
  const [editTypeThreeOpen, setEditTypeThreeOpen] = useState(false);
  const EditShowTypeThreeDrawer = () => {
    setEditTypeThreeOpen(true);
  };
  const onEditTypeThreeClose = () => {
    setEditTypeThreeOpen(false);
  };

  // Activity - Type Four
  const [addTypeFourOpen, setAddTypeFourOpen] = useState(false);
  const AddShowTypeFourDrawer = () => {
    setAddTypeFourOpen(true);
  };
  const onAddTypeFourClose = () => {
    setAddTypeFourOpen(false);
  };

  // Activity - Type Four - Edit
  const [editTypeFourOpen, setEditTypeFourOpen] = useState(false);
  const EditShowTypeFourDrawer = () => {
    setEditTypeFourOpen(true);
  };
  const onEditTypeFourClose = () => {
    setEditTypeFourOpen(false);
  };

  // Activity - Type Five
  const [addTypeFiveOpen, setAddTypeFiveOpen] = useState(false);
  const AddShowTypeFiveDrawer = () => {
    setAddTypeFiveOpen(true);
  };
  const onAddTypeFiveClose = () => {
    setAddTypeFiveOpen(false);
  };

  // Activity - Type Five - Edit
  const [editTypeFiveOpen, setEditTypeFiveOpen] = useState(false);
  const EditShowTypeFiveDrawer = () => {
    setEditTypeFiveOpen(true);
  };
  const onEditTypeFiveClose = () => {
    setEditTypeFiveOpen(false);
  };

  // Activity - Type Six
  const [addTypeSixOpen, setAddTypeSixOpen] = useState(false);
  const AddShowTypeSixDrawer = () => {
    setAddTypeSixOpen(true);
  };
  const onAddTypeSixClose = () => {
    setAddTypeSixOpen(false);
  };

  // Activity - Type Six - Edit
  const [editTypeSixOpen, setEditTypeSixOpen] = useState(false);
  const EditShowTypeSixDrawer = () => {
    setEditTypeSixOpen(true);
  };
  const onEditTypeSixClose = () => {
    setEditTypeSixOpen(false);
  };

  // Activity - Type Seven
  const [addTypeSevenOpen, setAddTypeSevenOpen] = useState(false);
  const AddShowTypeSevenDrawer = () => {
    setAddTypeSevenOpen(true);
  };
  const onAddTypeSevenClose = () => {
    setAddTypeSevenOpen(false);
  };

  // Activity - Type Seven - Edit
  const [editTypeSevenOpen, setEditTypeSevenOpen] = useState(false);
  const EditShowTypeSevenDrawer = () => {
    setEditTypeSevenOpen(true);
  };
  const onEditTypeSevenClose = () => {
    setEditTypeSevenOpen(false);
  };

  const [selectItems, setSelectItems] = useState([]);
  const [name, setName] = useState("");
  const inputRef = useRef(null);
  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const addItem = (e) => {
    e.preventDefault();
    setSelectItems([...selectItems, name || `New item`]);
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const handleDisorderChange = async (values) => {
    console.log(values, 'value');
    const newBody = { ...values, status: true};
    console.log(newBody, 'nB');

    try {
        const response = await disorderStore.addNewDisorder(newBody);
        
        if (response.success) {
            message.success("Disorder Added Successfully");

            const body = {
                id: "",
                searchQuery: disorderStore.disorderSearchQuery,
            };
          
           await disorderStore.getAllDisorderList(body);
            
            // Reset form and close drawer
            onAddClose();
            form.resetFields();
        } else {
            message.error("Failed to add disorder: " + (response.data.message || "Unknown error"));
        }
    } catch (error) {
        // Handle error here if needed
        message.error("Failed to add disorder: " + error.message);
        console.log(error, 'error');
    }
   };

  

  //check add more
  const [addMore, setAddMore] = useState(false);



  const handleAddNewActivity = async (data = []) => {
    console.log("add-activity");
    const { question_sentences, new_material, right_answer } = data;
    const { singleDisorderData } = disorderStore;
    const [{ _id }] = singleDisorderData;
    let formData = {};

    // try {
    //     const formObj = {
    //         disorderId: _id,
    //         name: "test kevin",
    //         description: "test kevin",
    //         materialType: "ACTIVITY",
    //         access: checkedValue,
    //         isNewMaterial: value,
    //         materialValue: new_material || form.getFieldValue("new_material"),
    //         activityType: "Test material value",
    //         question: question_sentences,
    //         rightAnswer: right_answer,
    //         uploadedFile: fileList,
    //         status: true,
    //     };

    if (data.material_access) {
      console.log("data-material-access");
      const formObj = {
        disorderId: _id,
        name: "test kevin",
        description: "test kevin",
        materialType: "ACTIVITY",
        access: checkedValue,
        isNewMaterial: value,
        materialValue: new_material,
        activityType: "Test material value",
        question: question_sentences,
        rightAnswer: right_answer,
        uploadedFile: fileList,
        status: true,
      };
      console.log(formObj);
      formData = createFormData(formObj);
      onAddTypeOneClose();
      addMore ? setAddMoreActivityDrawer() : setCheckedValue("");
      if (addMore) {
        setAddMoreActivityDrawer();
      } else {
        setCheckedValue("");
        onAddActivityClose();
        addActivityForm.resetFields();
      }
      
    } else {
      const formObj = {
        disorderId: _id,
        name: "test kevin",
        description: "test kevin",
        materialType: "ACTIVITY",
        access: checkedValue,
        isNewMaterial: value,
        materialValue: addActivityForm.getFieldValue("new_material"),
        activityType:  "Test material value",
        question: question_sentences,
        rightAnswer: right_answer,
        uploadedFile: fileList,
        status: true,
        materialId: activityStore.materialId,
      };
      formData = createFormData(formObj);
      if (addMore) {
        setTimeout(() => {
          setAddMoreActivityDrawer();
        }, 500);
      } else {
        setCheckedValue("");
        setAddMoreActivityClose();
        onAddActivityClose();
        addActivityForm.resetFields();
      }
      addMoreForm.resetFields();
    }

    const obj={
      disorderId:_id,
      materialType: "ACTIVITY",
    }
    
    console.log("data : ", data);

    // const formData = new FormData();
    // formData.append("disorderId", "66e55e295e8542b5a18945ab");
    // formData.append("name", "test");
    // formData.append("description", "test");
    // formData.append("materialType", "ACTIVITY");
    // formData.append("access", "PATIENT");
    // formData.append("isNewMaterial", true);
    // formData.append("materialValue", 123);
    // formData.append("status", true);
    // formData.append("activityType", "test");
    // formData.append("question", "test");
    // formData.append("rightAnswer", "test");
    // formData.append("uploadedFile", {});

    // Log FormData entries
    for (let pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }

    // const newBody = { ...data, status: true };

    await activityStore.addNewActivity(formData).then((data) => {
    


      // const body = {
      //   id: "",
      //   searchQuery: disorderStore.disorderSearchQuery,
      // };
      // disorderStore.getAllDisorderList(body);
    });

    message.success("Activity Added Successfully");
    await disorderStore.getMaterial(obj); 
    
        setFileList([]);
    // onAddClose();

}




  //handle checked value for material access
  const [checkedValue, setCheckedValue] = useState("");
  const [editCheckedValue, setEditCheckedValue] = useState("");

  const handleCheckboxChange = (checkedValues) => {
    const value =
      checkedValues.length > 1 ? checkedValues[1] : checkedValues[0];
    setCheckedValue(value);
  };

  const handleEditCheckboxChange = (checkedValues) => {
    const value =
      checkedValues.length > 1 ? checkedValues[1] : checkedValues[0];
      setEditCheckedValue(value);
  };

  useEffect(() => {
    if (!value) {
      addActivityForm.setFieldsValue({ new_material: "" });
    }
  }, [value, addActivityForm]);

  function createFormData(data) {
    const formData = new FormData();
    for (const key in data) {
      if (Array.isArray(data[key])) {
        data[key].forEach((file, index) => {
          formData.append(key, file.originFileObj); // Append each file
        });
      } else {
        formData.append(key, data[key]);
      }
    }
    return formData;
  }



  useEffect(() => {
  if (Object.keys(editableActivityData).length !== 0) {
      //get activity data based on material id
      console.log(editableActivityData.materialValue, "editable-material-value");
      const getActivityData = editableActivityData.activities.find((activity) => activity._id === activityId);
      editActivityForm.setFieldsValue({
        question_sentences: getActivityData.question,
        material_access: editableActivityData?.access[0],
        add_material: editableActivityData?.isNewMaterial ? 1 : 2,
        new_material: editableActivityData?.materialValue !== "undefined" ? editableActivityData?.materialValue : "",
        right_answer: getActivityData.rightAnswer,
        upload: getActivityData.activityFile,
      });
      setEditCheckedValue(editableActivityData?.access[0])
      const initialFileList =
      getActivityData.activityFile.map(
          (fileUrl, index) => ([{
            uid: `-1-${index}`, // Unique ID for each file
            name: fileUrl.split("/").pop(), // Extract file name from URL
            status: "done", // Mark as uploaded
            url: `https://api.scalegreat.in/${fileUrl}`, // Full file URL
          }])
        );

      setEditFileList(initialFileList);
    }
  }, [editableActivityData]);



 const handleEditActivity = async (data) => {
  console.log(data, 'datass')
    const { question_sentences, new_material, right_answer } = data;
    // const activityId = editableActivityData.activities.find((activity) => activity._id === materialId && );
    let formData = {};
    const { singleDisorderData } = disorderStore;
    const [{ _id }] = singleDisorderData;
    const updatedEditFileList = editFileList.map((file) => file[0]);

    const formObj = {
      activityId: activityId,
      name: "test kevin",
      description: "test kevin",
      materialType: "ACTIVITY",
      access: editCheckedValue,
      isNewMaterial: value,
      // ...(new_material ? {materialValue: new_material}:{}),
      materialValue: new_material,
      activityType: "Test material value",
      question: question_sentences,
      rightAnswer: right_answer,
      uploadedFile: updatedEditFileList,
      uploadStatus : uploadStatus ,
      status: editableActivityData.status,
    };

    formData = createFormData(formObj);
    setCheckedValue("");
    // onAddTypeOneClose();
    onEditTypeOneClose();
    editActivityForm.resetFields();

    const obj={
      disorderId:_id,
      materialType: "ACTIVITY",
    }


    try {
     const response= await activityStore.updateActivity(formData);
     console.log("response : ", response);
     console.log(response, 'response up')
       if (response.success) {
            message.success("Activity updated Successfully");
            await disorderStore.getMaterial(obj); 

            // Clear file list after successful addition
            setEditFileList([]);
            onAddActivityClose()
            onActivityOneClose()
        }
        // message.success("Activity Updated Successfully");
    } catch (error) {
        message.error("Failed to update activity: " + (error.message || "An unexpected error occurred"));
        console.error("Update Activity Error:", error); // Log the full error for debugging
    }
};


  // useEffect(() => {
  //   console.log("edit-file-list : ", editFileList);
  // },[editFileList])

  useEffect(() => {
    console.log("add-more-status : ", addMore);
  },[addMore])

  return (
    <Observer>
      {() => {
        const {
          updateDisorderModalStatus,
          singleDisorderData,
          setAddVideoMaterialModalStatus,
          activityMaterial,
        } = disorderStore;

        return (
          <>
            <Layout style={{ height: "100%", width: "100%" }}>
              <Sider
                style={{
                  backgroundColor: "#35A8DF",
                  height: "auto",
                  // borderRadius: 16,
                  marginTop: -10,
                  marginLeft: -10,
                  marginBottom: -10,
                }}
                trigger={null}
                collapsible
                collapsed={collapsed}
              >
                {!collapsed ? (
                  <Image
                    width={180}
                    src={Logo}
                    alt="Logo"
                    preview={false}
                    style={{
                      margin: 10,
                      padding: 10,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  />
                ) : (
                  <Image
                    width={70}
                    src={Logo}
                    alt="Logo"
                    preview={false}
                    style={{
                      margin: 10,
                      padding: 10,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  />
                )}
                <Menu
                  style={{
                    // backgroundColor: "#35A8DF",
                    // color: "#fff",
                    height: "100vh",
                  }}
                  mode="inline"
                  theme="dark"
                  defaultSelectedKeys={["/materials"]}
                  onClick={(item) => {
                    navigate(item.key);
                  }}
                  items={[
                    // {
                    //   key: "/dashboard",
                    //   icon: <BiHomeAlt2 />,
                    //   label: "Dashboard",
                    // },
                    // {
                    //   key: "/materials",
                    //   icon: <FaUserSecret />,
                    //   label: "Materials / Clinical resources management",
                    // },

                    {
                      key: "/users",
                      icon: <BiSolidUserAccount />,
                      label: "User management",
                    },
                    {
                      key: "subscription",
                      label: "Subscription",
                      icon: <BiSolidDollarCircle />,
                      children: [
                        {
                          key: "/slp-user-clinicians-subscription",
                          label: "Clinician",
                        },
                        {
                          key: "/caretaker-parents-consumer",
                          label: "Patient",
                        },
                      ],
                    },
                    {
                      key: "/materials",
                      icon: <BiSolidCloudUpload />,
                      label: "Materials Management",
                    },
                    {
                      key: "/faq",
                      icon: <BiSolidMessageSquareError />,
                      label: "FAQ",
                    },
                    {
                      key: "/feedbacks",
                      icon: <BiSolidLike />,
                      label: "Feedbacks",
                    },

                    // {
                    //   key: "/",
                    //   icon: <FaUsers />,
                    //   label: "Activity/Tasks manager",
                    // },
                    // {
                    //   key: "/reports",
                    //   icon: <FaUsers />,
                    //   label: "Reports",
                    // },
                    // {
                    //   key: "/kpiconfig",
                    //   icon: <RiCustomerService2Line />,
                    //   label: "KPI configuration",
                    // },
                    // {
                    //   key: "/settings",
                    //   icon: <FaTruckFast />,
                    //   label: "Profile Settings",
                    // },
                  ]}
                />
              </Sider>
              <Layout>
                <Header
                  style={{
                    padding: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    backgroundColor: "#fff",
                  }}
                >
                  <Button
                    type="text"
                    icon={collapsed ? <GiHamburgerMenu /> : <LeftOutlined />}
                    onClick={() => setCollapsed(!collapsed)}
                    style={{
                      fontSize: "16px",
                      width: 64,
                      height: 64,
                    }}
                  />
                  <div>
                    <Dropdown
                      menu={{
                        items,
                      }}
                    >
                      <a onClick={(e) => e.preventDefault()}>
                        <Avatar
                          src={<img src={AvatarImg} alt="avatar" />}
                          shape="square"
                          size="large"
                        />
                      </a>
                    </Dropdown>
                  </div>
                </Header>
                <Content
                  style={{
                    backgroundColor: "#F1F5FF",
                    padding: 20,
                  }}
                >
                  <Row>
                    <Col span={24}>
                      <div className="dashboard_top">
                        <div className="title">
                          Materials / Clinical Resources Management
                        </div>
                        <div className="add_data_dashboard">
                          <Input
                            size="large"
                            placeholder="Search"
                            prefix={<SearchOutlined />}
                            className="mr_5"
                            onChange={(e) =>
                              disorderStore.getAllDisorderList({
                                searchQuery: e.target.value,
                              })
                            }
                          />
                          <Button
                            type="primary"
                            size="large"
                            icon={<PlusOutlined />}
                            onClick={AddShowDrawer}
                          >
                            Add Disorder
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row gutter={24} className="mb_20">
                    <Col span={6}>
                      <Card bordered={true}>
                        <div className="materials_card">
                          <div className="icon">
                            <DisordersIcon />
                          </div>

                          <Statistic
                            title="Total Disorders"
                            value={disorderStore.disorderCounts.totalDisorders}
                            style={{ fontWeight: 600 }}
                            valueStyle={{
                              color: "#27A9E0",
                              fontSize: 16,
                            }}
                            // prefix={<ArrowUpOutlined />}
                            // suffix="+"
                          />
                        </div>
                      </Card>
                    </Col>
                    <Col span={6}>
                      <Card bordered={true}>
                        <div className="materials_card">
                          <div className="icon">
                            <MaterialsIcon />
                          </div>
                          <Statistic
                            title="Total Materials"
                            value={disorderStore.disorderCounts.totalMaterials}
                            style={{ fontWeight: 600 }}
                            valueStyle={{
                              color: "#27A9E0",
                              fontSize: 16,
                            }}
                            // prefix={<ArrowUpOutlined />}
                            // suffix="+"
                          />
                        </div>
                      </Card>
                    </Col>
                  </Row>

                  <Collapse
                    style={{
                      background: "transparent",
                      border: "none",
                      paddingBottom: 50,
                    }}
                    items={itemsData(disorderStore.disorderList)}
                    defaultActiveKey={["1"]}onAddClose
                  />
                </Content>
              </Layout>
            </Layout>

            {/* main add Drawer */}
            <Drawer
              title="Add Disorders"
              width={500}
              onClose={onAddClose}
              open={addOpen}
              maskClosable={false}
            >
              <Form onFinish={handleDisorderChange} form={form} layout="vertical">
                <Row>
                  <Col span="24">
                    <Form.Item
                      layout="vertical"
                      label="DISORDER NAME"
                      name="name"
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      className="mb_10"
                      rules={[
                        {
                          required: true,
                          message: "Please enter disorder name",
                        },
                      ]}
                    >
                      <Input size="large" />
                    </Form.Item>
                  </Col>
                  <Col span="24">
                    <Form.Item
                      layout="vertical"
                      label="DESCRIPTION"
                      name="description"
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      className="mb_10"
                      rules={[
                        {
                          required: true,
                          message: "Please enter disorder description",
                        },
                      ]}
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </Col>
                  <Col span="24">
                    <Form.Item
                      layout="vertical"
                      label="SELECT MATERIAL"
                      name="materialType"
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      className="mb_10"
                      rules={[
                        {
                          required: true,
                          message: "Please select material type!",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        placeholder="select Material"
                        size="large"
                        style={{
                          width: "100%",
                        }}
                        options={[
                          {
                            value: "VIDEO",
                            label: "Videos",
                          },
                          {
                            value: "DOCUMENT",
                            label: "Documents",
                          },
                          {
                            value: "ACTIVITY",
                            label: "Activity",
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col span="24" className="mt_15">
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        type="primary"
                        size="large"
                        className="w_100"
                      >
                        Save
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Drawer>

            {/* main edit Drawer */}
            <Drawer
              title="Edit Disorders"
              width={500}
              onClose={onEditClose}
              open={updateDisorderModalStatus}
              maskClosable={false}
            >
              <Form form={editDisorderForm} onFinish={handleDisorderUpdate} layout="vertical">
                <Row>
                  <>
                    <Col span="24">
                      <Form.Item
                        layout="vertical"
                        label="DISORDER NAME"
                        name="name"
                        labelCol={{
                          span: 24,
                        }}
                        wrapperCol={{
                          span: 24,
                        }}
                        className="mb_10"
                        rules={[
                          {
                            required: true,
                            message: "Please enter disorder name",
                          },
                        ]}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                    <Col span="24">
                      <Form.Item
                        layout="vertical"
                        label="DESCRIPTION"
                        name="description"
                        labelCol={{
                          span: 24,
                        }}
                        wrapperCol={{
                          span: 24,
                        }}
                        className="mb_10"
                        rules={[
                          {
                            required: true,
                            message: "Please enter disorder description",
                          },
                        ]}
                      >
                        <TextArea rows={4} />
                      </Form.Item>
                    </Col>
                    <Col span="24">
                      <Form.Item
                        layout="vertical"
                        label="SELECT MATERIAL"
                        name="materialType"
                        labelCol={{
                          span: 24,
                        }}
                        wrapperCol={{
                          span: 24,
                        }}
                        className="mb_10"
                        rules={[
                          {
                            required: true,
                            message: "Please select material type!",
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          placeholder="select Material"
                          size="large"
                          style={{
                            width: "100%",
                          }}
                          options={[
                            {
                              value: "VIDEO",
                              label: "Videos",
                            },
                            {
                              value: "DOCUMENT",
                              label: "Documents",
                            },
                            {
                              value: "ACTIVITY",
                              label: "Activity",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span="24" className="mt_15">
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        className="w_100"
                      >
                        Save
                      </Button>
                    </Col>
                  </>
                </Row>
              </Form>
            </Drawer>

            {/* Video */}
      
            <VideoMaterialDrawer />

            {/* Add Video Drawer */}
            <AddNewVideoDrawer />

            {/* View Video Drawer */}
            <VIewVideoMaterial />

            {/* Document */}
            <DocumentMaterialDrawer EditShowDocDrawer={EditShowDocDrawer} />

            {/* Add Document Drawer */}
            <AddNewDocumentDrawer />

            {/* Edit Document Drawer */}
            <EditNewDocumentDrawer
              editDocOpen={editDocOpen}
              onEditDocClose={onEditDocClose}
            />

            {/* View Document Drawer */}
            <ViewDocumentMaterial />

            {/* Activity */}
            <Drawer
              title={
                <>
                  <div className="d_flex justify_content_between align_items_center">
                    <div>Activity</div>
                    <Button type="primary" onClick={AddShowActivityDrawer}>
                      Add Activity
                    </Button>
                  </div>
                </>
              }
              width={750}
              onClose={onActivityOneClose}
              open={ActivityOneOpen}
              maskClosable={false}
            >
            {ActivityOneOpen &&
              <ActivityList
              confirm={confirm}
              cancel={cancel}
              ViewShowDocDrawer={ViewShowDocDrawer}
              isOpen={ActivityOneOpen}
              EditShowTypeOneDrawer={EditShowTypeOneDrawer}
            />
            }
              
             
            </Drawer>

            {/* Add Activity Drawer */}
            <Drawer
              title="Create a Activity Material"
              width={640}
              onClose={onAddActivityClose}
              open={addActivityOpen}
              maskClosable={false}
            >
              <Row>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="SELECT ACTIVITY TYPE"
                    name="select_activity_type"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Select
                      placeholder="Select Activity Type"
                      size="large"
                      style={{
                        width: "100%",
                      }}
                      value={""}
                      onChange={(e) => setCurrentValue(e)}
                      options={[
                        {
                          value: "0",
                          label: (
                            <>
                              <span>Select Activity Type.</span>
                            </>
                          ),
                        },
                        {
                          value: "1",
                          label: (
                            <>
                              <span>Form The Sentence Based On 2 Images</span>
                            </>
                          ),
                        },
                       
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Button
                    type="primary"
                    size="large"
                    className="mt_10"
                    onClick={() => {
                      if (currentValue == "1") {
                        AddShowTypeOneDrawer();
                      } else if (currentValue == "2") {
                        AddShowTypeTwoDrawer();
                      } else if (currentValue == "3") {
                        AddShowTypeThreeDrawer();
                      } else if (currentValue == "4") {
                        AddShowTypeFourDrawer();
                      } else if (currentValue == "5") {
                        AddShowTypeFiveDrawer();
                      } else if (currentValue == "6") {
                        AddShowTypeSixDrawer();
                      } else if (currentValue == "7") {
                        AddShowTypeSevenDrawer();
                      }
                    }}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </Drawer>

            {/* Add Type One Drawer */}
            <Drawer
              title="Form The Sentence Based On 2 Images"
              width={580}
              onClose={onAddTypeOneClose}
              open={addTypeOneOpen}
              maskClosable={false}
            >
              <Form
                onFinish={(value) => {
                  handleAddNewActivity(value);
                }}
                form={addActivityForm}
                layout="vertical"
              >
                <Row>
                  <Col span="24">
                    <Form.Item
                      // layout="vertical"
                      label="ENTER QUESTION SENTENCE"
                      name="question_sentences"
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter question sentence",
                        },
                      ]}
                      className="mb_10"
                    >
                      <Input size="large" />
                    </Form.Item>
                  </Col>
                  <Col span="24">
                    <Form.Item
                      // layout="vertical"
                      label="UPLOAD"
                      name="upload"
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      key={fileList.length <= 2 ? "required" : "not-required"}
                      rules={
                        fileList.length < 2
                          ? [
                              {
                                required: true,
                                message: "Please upload an image",
                              },
                            ]
                          : []
                      }
                      className="mb_10"
                    >
                      <Upload
                        action={fileList}
                        listType="picture-card"
                        name="upload"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                        beforeUpload={() => {
                          return false;
                        }}
                      >
                        {fileList.length >= 2 ? null : uploadButton}
                      </Upload>
                      {previewImage && (
                        <Image
                          wrapperStyle={{
                            display: "none",
                          }}
                          preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) =>
                              setPreviewOpen(visible),
                            afterOpenChange: (visible) =>
                              !visible && setPreviewImage(""),
                          }}
                          src={previewImage}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span="24">
                    <Form.Item
                      // layout="vertical"
                      label="MATERIAL ACCESS"
                      name="material_access"
                      valuePropName="checked"
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      className="mb_10"
                      rules={[
                        {
                          required: true,
                          message: "Please select one option",
                        },
                      ]}
                    >
                      <Checkbox.Group 
                        name="material_access"
                        value={checkedValue}
                        onChange={handleCheckboxChange}
                      >
                        <Checkbox value={"PATIENT"}>Patient’s</Checkbox>
                        <Checkbox value={"CLINIC"}>Clinician’s</Checkbox>
                      </Checkbox.Group>
                    </Form.Item>
                  </Col>
                  <Col span="24">
                    <Form.Item
                      // layout="vertical"
                      label="CAN WE ADD AS A NEW MATERIAL?"
                      name="add_material"
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      rules={[
                        {
                          required: true,
                          message: "Please select one option",
                        },
                      ]}
                      className="mb_10"
                    >
                      <Radio.Group onChange={onChangeRatio} value={value} name="add_material">
                        <Radio value={1}>Yes</Radio>
                        <Radio value={2}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span="24">
                    <Form.Item
                      // layout="vertical"
                      label="ENTER NEW MATERIAL VALUE IN SAR"
                      name="new_material"
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      key={value ? "required" : "not-required"}
                      rules={
                        value
                          ? [
                              {
                                required: value,
                                message: "Please enter material value in SAR",
                              },
                            ]
                          : []
                      }
                      className="mb_10"
                    >
                      <Input size="large" disabled={!value} />
                    </Form.Item>
                  </Col>
                  <Col span="24">
                    <Form.Item
                      // layout="vertical"
                      label="RIGHT ANSWER"
                      name="right_answer"
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter right answer",
                        },
                      ]}
                      className="mb_10"
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </Col>
                  <Col span="24" className="mt_15">
                    <Form.Item>
                      <Flex align="end" gap="small">
                        <Button
                          type="primary"
                          typeof="submit"
                          htmlType="submit"
                          size="large"
                          className="w_100"
                          onClick={() => setAddMore(false)}
                        >
                          Add & Save
                        </Button>
                        <Button
                          size="large"
                          className="w_100"
                          htmlType="submit"
                          // onClick={handleAddMoreActivity}
                          onClick={() => setAddMore(true)}
                        >
                          Add More
                        </Button>
                      </Flex>
                    </Form.Item>
                    
                  </Col>
                </Row>
              </Form>
            </Drawer>

            {/* Add Drawer Card 1 */}
            <Drawer
              title="Form The Sentence Based On 2 Images"
              width={580}
              onClose={setAddMoreActivityClose}
              open={addMoreActivityOpen}
              maskClosable={false}
            >
              <Form
                onFinish={(value) => {
                  handleAddNewActivity(value);
                }}
                form={addMoreForm}
                layout="vertical"
              >
                <Row>
                  <Col span="24">
                    <Form.Item
                      layout="vertical"
                      label="ENTER QUESTION SENTENCES"
                      name="question_sentences"
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter question sentence",
                        },
                      ]}
                      className="mb_10"
                    >
                      <Input size="large" />
                    </Form.Item>
                  </Col>
                  <Col span="24">
                    <Form.Item
                      layout="vertical"
                      label="UPLOAD"
                      name="upload"
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      key={fileList.length < 2 ? "required" : "not-required"}
                      rules={
                        fileList.length < 2
                          ? [
                              {
                                required: true,
                                message: "Please upload an image",
                              },
                            ]
                          : []
                      }
                      className="mb_10"
                    >
                      <Upload
                        action={fileList}
                        listType="picture-card"
                        name="upload"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                        beforeUpload={() => {
                          return false;
                        }}
                      >
                        {fileList.length >= 2 ? null : uploadButton}
                      </Upload>
                      {previewImage && (
                        <Image
                          wrapperStyle={{
                            display: "none",
                          }}
                          preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) =>
                              setPreviewOpen(visible),
                            afterOpenChange: (visible) =>
                              !visible && setPreviewImage(""),
                          }}
                          src={previewImage}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span="24">
                    <Form.Item
                      layout="vertical"
                      label="RIGHT ANSWER"
                      name="right_answer"
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter right answer",
                        },
                      ]}
                      className="mb_10"
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </Col>
                  <Col span="24" className="mt_15">
                    <Flex align="end" gap="small">
                      <Button
                        type="primary"
                        size="large"
                        className="w_100"
                        htmlType="submit"
                        // onClick={setAddMoreActivityClose}
                        onClick={() => setAddMore(false)}
                      >
                        Add & Save
                      </Button>
                      <Button
                        size="large"
                        className="w_100"
                        htmlType="submit"
                        // onClick={setAddMoreActivityDrawer1}
                        onClick={() => setAddMore(true)}
                      >
                        Add More
                      </Button>
                    </Flex>
                  </Col>
                </Row>
              </Form>
            </Drawer>

            {/* Add Drawer Card 2 */}
            {/* <Drawer
              title="Form The Sentence Based On 2 Images"
              width={580}
              onClose={setAddMoreActivityClose1}
              open={addMoreActivity1Open}
              maskClosable={false}
            >
              <Row>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ENTER QUESTION SENTENCES"
                    name="question_sentences"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="UPLOAD"
                    name="upload"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Upload
                      action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                    {previewImage && (
                      <Image
                        wrapperStyle={{
                          display: "none",
                        }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) =>
                            !visible && setPreviewImage(""),
                        }}
                        src={previewImage}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="RIGHT ANSWER"
                    name="right_answer"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
                <Col span="24" className="mt_15">
                  <Flex align="end" gap="small">
                    <Button type="primary" size="large" className="w_100">
                      Add & Save
                    </Button>
                    <Button
                      size="large"
                      className="w_100"
                      onClick={setAddMoreActivityDrawer}
                    >
                      Add More
                    </Button>
                  </Flex>
                </Col>
              </Row>
            </Drawer> */}

            {/* Add Drawer Card 3 */}
            {/* <Drawer
              title="Form The Sentence Based On 2 Images --3"
              width={580}
              onClose={setAddMoreActivityClose}
              open={addMoreActivityOpen}
              maskClosable={false}
            >
              <Row>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ENTER QUESTION SENTENCES"
                    name="question_sentences"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="UPLOAD"
                    name="upload"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Upload
                      action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                    {previewImage && (
                      <Image
                        wrapperStyle={{
                          display: "none",
                        }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) =>
                            !visible && setPreviewImage(""),
                        }}
                        src={previewImage}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="RIGHT ANSWER"
                    name="right_answer"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
                <Col span="24" className="mt_15">
                  <Flex align="end" gap="small">
                    <Button type="primary" size="large" className="w_100">
                      Add & Save
                    </Button>
                    <Button
                      size="large"
                      className="w_100"
                      onClick={setAddMoreActivityDrawer}
                    >
                      Add More
                    </Button>
                  </Flex>
                </Col>
              </Row>
            </Drawer> */}

            {/* Add Drawer Card 4 */}
            {/* <Drawer
              title="Form The Sentence Based On 2 Images --4"
              width={580}
              onClose={setAddMoreActivityClose}
              open={addMoreActivityOpen}
              maskClosable={false}
            >
              <Row>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ENTER QUESTION SENTENCES"
                    name="question_sentences"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="UPLOAD"
                    name="upload"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Upload
                      action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                    {previewImage && (
                      <Image
                        wrapperStyle={{
                          display: "none",
                        }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) =>
                            !visible && setPreviewImage(""),
                        }}
                        src={previewImage}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="RIGHT ANSWER"
                    name="right_answer"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
                <Col span="24" className="mt_15">
                  <Flex align="end" gap="small">
                    <Button type="primary" size="large" className="w_100">
                      Add & Save
                    </Button>
                    <Button
                      size="large"
                      className="w_100"
                      onClick={setAddMoreActivityDrawer}
                    >
                      Add More
                    </Button>
                  </Flex>
                </Col>
              </Row>
            </Drawer> */}

            {/* Add Type Two Drawer */}
            <Drawer
              title="Select A Right Answers Based On Right Text"
              width={580}
              onClose={onAddTypeTwoClose}
              open={addTypeTwoOpen}
              maskClosable={false}
            >
              <Row>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ENTER QUESTION SENTENCES"
                    name="question_sentences"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="SELECT MATERIAL"
                    name="select_material"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Select
                      size="large"
                      style={{
                        width: "100%",
                      }}
                      placeholder="Add Material"
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider
                            style={{
                              margin: "8px 0",
                            }}
                          />
                          <Space
                            style={{
                              padding: "0 8px 4px",
                            }}
                          >
                            <Input
                              placeholder="Please enter item"
                              ref={inputRef}
                              value={name}
                              onChange={onNameChange}
                              onKeyDown={(e) => e.stopPropagation()}
                            />
                            <Button
                              type="text"
                              icon={<PlusOutlined />}
                              onClick={addItem}
                            >
                              Add item
                            </Button>
                          </Space>
                        </>
                      )}
                      options={selectItems.map((item) => ({
                        label: item,
                        value: item,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="MATERIAL ACCESS"
                    name="material_access"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Checkbox defaultChecked={true}>Patient’s</Checkbox>
                    <Checkbox>Clinician’s</Checkbox>
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="CAN WE ADD AS A NEW MATERIAL?"
                    name="add_material"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Radio.Group onChange={onChangeRatio} value={value}>
                      <Radio value={1}>Yes</Radio>
                      <Radio value={2}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ENTER NEW MATERIAL VALUE IN SAR"
                    name="new_material"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="RIGHT ANSWER"
                    name="right_answer"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
                <Col span="24" className="mt_15">
                  <Button type="primary" size="large" className="w_100">
                    Save
                  </Button>
                </Col>
              </Row>
            </Drawer>

            {/* Add Type Three Drawer */}
            <Drawer
              title="Select A Right Answers Based On Image"
              width={580}
              onClose={onAddTypeThreeClose}
              open={addTypeThreeOpen}
              maskClosable={false}
            >
              <Row>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ENTER QUESTION SENTENCES"
                    name="question_sentences"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="UPLOAD"
                    name="upload"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Upload
                      action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                    {previewImage && (
                      <Image
                        wrapperStyle={{
                          display: "none",
                        }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) =>
                            !visible && setPreviewImage(""),
                        }}
                        src={previewImage}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="MATERIAL ACCESS"
                    name="material_access"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Checkbox defaultChecked={true}>Patient’s</Checkbox>
                    <Checkbox>Clinician’s</Checkbox>
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="CAN WE ADD AS A NEW MATERIAL?"
                    name="add_material"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Radio.Group onChange={onChangeRatio} value={value}>
                      <Radio value={1}>Yes</Radio>
                      <Radio value={2}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ENTER NEW MATERIAL VALUE IN SAR"
                    name="new_material"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ADD ANSWERS OPTIONS"
                    name="answers_options"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Select
                      size="large"
                      style={{
                        width: "100%",
                      }}
                      placeholder="Add ANSWERS OPTIONS"
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider
                            style={{
                              margin: "8px 0",
                            }}
                          />
                          <Space
                            style={{
                              padding: "0 8px 4px",
                            }}
                          >
                            <Input
                              placeholder="Please enter item"
                              ref={inputRef}
                              value={name}
                              onChange={onNameChange}
                              onKeyDown={(e) => e.stopPropagation()}
                            />
                            <Button
                              type="text"
                              icon={<PlusOutlined />}
                              onClick={addItem}
                            >
                              Add item
                            </Button>
                          </Space>
                        </>
                      )}
                      options={selectItems.map((item) => ({
                        label: item,
                        value: item,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="RIGHT ANSWER"
                    name="right_answer"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
                <Col span="24" className="mt_15">
                  <Button type="primary" size="large" className="w_100">
                    Save
                  </Button>
                </Col>
              </Row>
            </Drawer>

            {/* Add Type Four Drawer */}
            <Drawer
              title="Select Correct Image"
              width={580}
              onClose={onAddTypeFourClose}
              open={addTypeFourOpen}
              maskClosable={false}
            >
              <Row>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ENTER QUESTION SENTENCES"
                    name="question_sentences"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="UPLOAD"
                    name="upload"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Upload
                      action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                    {previewImage && (
                      <Image
                        wrapperStyle={{
                          display: "none",
                        }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) =>
                            !visible && setPreviewImage(""),
                        }}
                        src={previewImage}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="MATERIAL ACCESS"
                    name="material_access"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Checkbox defaultChecked={true}>Patient’s</Checkbox>
                    <Checkbox>Clinician’s</Checkbox>
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="CAN WE ADD AS A NEW MATERIAL?"
                    name="add_material"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Radio.Group onChange={onChangeRatio} value={value}>
                      <Radio value={1}>Yes</Radio>
                      <Radio value={2}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ENTER NEW MATERIAL VALUE IN SAR"
                    name="new_material"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="RIGHT ANSWER IMAGE"
                    name="right_answer_image"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    {/* <TextArea rows={4} /> */}
                  </Form.Item>
                </Col>
                <Col span="24" className="mt_15">
                  <Button type="primary" size="large" className="w_100">
                    Save
                  </Button>
                </Col>
              </Row>
            </Drawer>

            {/* Add Type Five Drawer */}
            <Drawer
              title="Write A One Word Answer Based On 6 Images"
              width={580}
              onClose={onAddTypeFiveClose}
              open={addTypeFiveOpen}
              maskClosable={false}
            >
              <Row>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ENTER QUESTION SENTENCES"
                    name="question_sentences"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="UPLOAD"
                    name="upload"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Upload
                      action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                    {previewImage && (
                      <Image
                        wrapperStyle={{
                          display: "none",
                        }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) =>
                            !visible && setPreviewImage(""),
                        }}
                        src={previewImage}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="MATERIAL ACCESS"
                    name="material_access"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Checkbox defaultChecked={true}>Patient’s</Checkbox>
                    <Checkbox>Clinician’s</Checkbox>
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="CAN WE ADD AS A NEW MATERIAL?"
                    name="add_material"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Radio.Group onChange={onChangeRatio} value={value}>
                      <Radio value={1}>Yes</Radio>
                      <Radio value={2}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ENTER NEW MATERIAL VALUE IN SAR"
                    name="new_material"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="RIGHT ANSWER TEXT"
                    name="right_answer_text"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
                <Col span="24" className="mt_15">
                  <Button type="primary" size="large" className="w_100">
                    Save
                  </Button>
                </Col>
              </Row>
            </Drawer>

            {/* Add Type Six Drawer */}
            <Drawer
              title="Write the Correct Sentence Based On 6 Images"
              width={580}
              onClose={onAddTypeSixClose}
              open={addTypeSixOpen}
              maskClosable={false}
            >
              <Row>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ENTER QUESTION SENTENCES"
                    name="question_sentences"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="UPLOAD"
                    name="upload"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Upload
                      action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                    {previewImage && (
                      <Image
                        wrapperStyle={{
                          display: "none",
                        }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) =>
                            !visible && setPreviewImage(""),
                        }}
                        src={previewImage}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="MATERIAL ACCESS"
                    name="material_access"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Checkbox defaultChecked={true}>Patient’s</Checkbox>
                    <Checkbox>Clinician’s</Checkbox>
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="CAN WE ADD AS A NEW MATERIAL?"
                    name="add_material"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Radio.Group onChange={onChangeRatio} value={value}>
                      <Radio value={1}>Yes</Radio>
                      <Radio value={2}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ENTER NEW MATERIAL VALUE IN SAR"
                    name="new_material"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="RIGHT ANSWER SENTENCE"
                    name="right_answer_sentence"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
                <Col span="24" className="mt_15">
                  <Button type="primary" size="large" className="w_100">
                    Save
                  </Button>
                </Col>
              </Row>
            </Drawer>

            {/* Add Type Seven Drawer */}
            <Drawer
              title="Form The Sentence Based On 4 Images And Images Title"
              width={580}
              onClose={onAddTypeSevenClose}
              open={addTypeSevenOpen}
              maskClosable={false}
            >
              <Row>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ENTER QUESTION SENTENCES"
                    name="question_sentences"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="UPLOAD IMAGE 1"
                    name="upload"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Upload
                      action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                    {previewImage && (
                      <Image
                        wrapperStyle={{
                          display: "none",
                        }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) =>
                            !visible && setPreviewImage(""),
                        }}
                        src={previewImage}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="TITLE 1"
                    name="title_one"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="UPLOAD IMAGE 2"
                    name="upload_image_two"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Upload
                      action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                    {previewImage && (
                      <Image
                        wrapperStyle={{
                          display: "none",
                        }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) =>
                            !visible && setPreviewImage(""),
                        }}
                        src={previewImage}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="TITLE 2"
                    name="title_two"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="UPLOAD IMAGE 3"
                    name="upload_image_three"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Upload
                      action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                    {previewImage && (
                      <Image
                        wrapperStyle={{
                          display: "none",
                        }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) =>
                            !visible && setPreviewImage(""),
                        }}
                        src={previewImage}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="TITLE 3"
                    name="title_three"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="UPLOAD IMAGE 4"
                    name="upload_image_four"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Upload
                      action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                    {previewImage && (
                      <Image
                        wrapperStyle={{
                          display: "none",
                        }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) =>
                            !visible && setPreviewImage(""),
                        }}
                        src={previewImage}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="TITLE 4"
                    name="title_four"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="COMPLETE THE SENTENCE"
                    name="complete_the_sentence"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
                <Col span="24" className="mt_15">
                  <Button type="primary" size="large" className="w_100">
                    Save
                  </Button>
                </Col>
              </Row>
            </Drawer>

            {/* =================== Edit ================== */}

            {/* Edit Type One Drawer */}
            <Drawer
              title="Edit Form The Sentence Based On 2 Images"
              width={580}
              onClose={onEditTypeOneClose}
              open={editTypeOneOpen}
              maskClosable={false}
            >
              <Form
                onFinish={(value) => {
                  handleEditActivity(value);
                }}
                form={editActivityForm}
                layout="vertical"
              >
                <Row>
                  <Col span="24">
                    <Form.Item
                      layout="vertical"
                      label="ENTER QUESTION SENTENCES"
                      name="question_sentences"
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter question sentence",
                        },
                      ]}
                      className="mb_10"
                    >
                      <Input size="large" />
                    </Form.Item>
                  </Col>
                  <Col span="24">
                    <Form.Item
                      layout="vertical"
                      label="UPLOAD"
                      name="upload"
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      key={editFileList.length <= 2 ? "required" : "not-required"}
                      dependencies={['upload']}
                      rules={[
                        {
                          required: true,
                          message: "Please upload image",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const uploadedImagesCount = editFileList.reduce(
                              (count, fileList) => count + (fileList.length > 0 ? 1 : 0),
                              0
                            );
                            if (uploadedImagesCount >= 2) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('Please upload image'));
                          },
                        }),
                      ]}
                      className="mb_10"
                    >
                      {/* <Upload
                        action={editFileList}
                        listType="picture-card"
                        name="upload"
                        fileList={editFileList}
                        onPreview={handlePreview}
                        onChange={handleEditChange}
                        onRemove={handleRemove}
                        beforeUpload={() => {
                          return false;
                        }}
                      >
                        {editFileList.length >= 2 ? null : uploadButton}
                      </Upload>
                      {previewImage && (
                        <Image
                          wrapperStyle={{
                            display: "none",
                          }}
                          preview={{
                            visible: false,
                            onVisibleChange: (visible) =>
                              setPreviewOpen(visible),
                            afterOpenChange: (visible) =>
                              !visible && setPreviewImage(""),
                          }}
                          src={previewImage}
                        />
                      )} */}
                      <div style={{display: "flex", gap: "10px"}}>
                      {editFileList.map((fileList, index) => (
                            <Upload
                              
                              key={index}
                              listType="picture-card"
                              fileList={fileList}

                              onPreview={handlePreview}
                              onChange={(newFileList) => handleEditChange(index, newFileList)}
                              beforeUpload={() => false}
                            >
                              {fileList.length === 0 ? uploadButton : null}
                            </Upload>
                          ))}
                      </div>
                      {previewImage && (
                        <Image
                          wrapperStyle={{ display: 'none' }}
                          preview={{
                            visible: previewOpen,
                            onVisibleChange: setPreviewOpen,
                            afterOpenChange: (visible) => !visible && setPreviewImage(''),
                          }}
                          src={previewImage}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span="24">
                    <Form.Item
                      layout="vertical"
                      label="MATERIAL ACCESS"
                      name="material_access"
                      valuePropName="checked"
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      className="mb_10"
                      rules={[
                        {
                          required: true,
                          message: "Please select one option",
                        },
                      ]}
                    >
                      <Checkbox.Group
                        value={editCheckedValue}
                        onChange={handleEditCheckboxChange}
                      >
                        <Checkbox value={"PATIENT"}>Patient’s</Checkbox>
                        <Checkbox value={"CLINIC"}>Clinician’s</Checkbox>
                      </Checkbox.Group>
                    </Form.Item>
                  </Col>
                  <Col span="24">
                    <Form.Item
                      layout="vertical"
                      label="CAN WE ADD AS A NEW MATERIAL?"
                      name="add_material"
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      rules={[
                        {
                          required: true,
                          message: "Please select one option",
                        },
                      ]}
                      className="mb_10"
                    >
                      <Radio.Group onChange={onChangeRatio} value={value}>
                        <Radio value={1}>Yes</Radio>
                        <Radio value={2}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span="24">
                    <Form.Item
                      layout="vertical"
                      label="ENTER NEW MATERIAL VALUE IN SAR"
                      name="new_material"
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      key={value ? "required" : "not-required"}
                      rules={
                        value
                          ? [
                              {
                                required: value,
                                message: "Please enter material value in SAR",
                              },
                            ]
                          : []
                      }
                      className="mb_10"
                    >
                      <Input size="large" disabled={!value && editableActivityData.materialValue === ""} />
                    </Form.Item>
                  </Col>
                  <Col span="24">
                    <Form.Item
                      layout="vertical"
                      label="RIGHT ANSWER"
                      name="right_answer"
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter right answer",
                        },
                      ]}
                      className="mb_10"
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </Col>
                  <Col span="24" className="mt_15">
                    <Button
                      type="primary"
                      size="large"
                      className="w_100"
                      htmlType="submit"
                    >
                      Update
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Drawer>

            {/* Edit Type Two Drawer */}
            <Drawer
              title="Edit Right Answers Based On Right Text"
              width={580}
              onClose={onEditTypeTwoClose}
              open={editTypeTwoOpen}
              maskClosable={false}
            >
              <Row>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ENTER QUESTION SENTENCES"
                    name="question_sentences"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="SELECT MATERIAL"
                    name="select_material"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Select
                      size="large"
                      style={{
                        width: "100%",
                      }}
                      placeholder="Add Material"
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider
                            style={{
                              margin: "8px 0",
                            }}
                          />
                          <Space
                            style={{
                              padding: "0 8px 4px",
                            }}
                          >
                            <Input
                              placeholder="Please enter item"
                              ref={inputRef}
                              value={name}
                              onChange={onNameChange}
                              onKeyDown={(e) => e.stopPropagation()}
                            />
                            <Button
                              type="text"
                              icon={<PlusOutlined />}
                              onClick={addItem}
                            >
                              Add item
                            </Button>
                          </Space>
                        </>
                      )}
                      options={selectItems.map((item) => ({
                        label: item,
                        value: item,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="MATERIAL ACCESS"
                    name="material_access"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Checkbox defaultChecked={true}>Patient’s</Checkbox>
                    <Checkbox>Clinician’s</Checkbox>
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="CAN WE ADD AS A NEW MATERIAL?"
                    name="add_material"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Radio.Group onChange={onChangeRatio} value={value}>
                      <Radio value={1}>Yes</Radio>
                      <Radio value={2}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ENTER NEW MATERIAL VALUE IN SAR"
                    name="new_material"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="RIGHT ANSWER"
                    name="right_answer"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
                <Col span="24" className="mt_15">
                  <Button type="primary" size="large" className="w_100">
                    Update
                  </Button>
                </Col>
              </Row>
            </Drawer>

            {/* Edit Type Three Drawer */}
            <Drawer
              title="Edit Right Answers Based On Image"
              width={580}
              onClose={onEditTypeThreeClose}
              open={editTypeThreeOpen}
              maskClosable={false}
            >
              <Row>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ENTER QUESTION SENTENCES"
                    name="question_sentences"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="UPLOAD"
                    name="upload"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Upload
                      action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                    {previewImage && (
                      <Image
                        wrapperStyle={{
                          display: "none",
                        }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) =>
                            !visible && setPreviewImage(""),
                        }}
                        src={previewImage}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="MATERIAL ACCESS"
                    name="material_access"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Checkbox defaultChecked={true}>Patient’s</Checkbox>
                    <Checkbox>Clinician’s</Checkbox>
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="CAN WE ADD AS A NEW MATERIAL?"
                    name="add_material"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Radio.Group onChange={onChangeRatio} value={value}>
                      <Radio value={1}>Yes</Radio>
                      <Radio value={2}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ENTER NEW MATERIAL VALUE IN SAR"
                    name="new_material"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ADD ANSWERS OPTIONS"
                    name="answers_options"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Select
                      size="large"
                      style={{
                        width: "100%",
                      }}
                      placeholder="Add ANSWERS OPTIONS"
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider
                            style={{
                              margin: "8px 0",
                            }}
                          />
                          <Space
                            style={{
                              padding: "0 8px 4px",
                            }}
                          >
                            <Input
                              placeholder="Please enter item"
                              ref={inputRef}
                              value={name}
                              onChange={onNameChange}
                              onKeyDown={(e) => e.stopPropagation()}
                            />
                            <Button
                              type="text"
                              icon={<PlusOutlined />}
                              onClick={addItem}
                            >
                              Add item
                            </Button>
                          </Space>
                        </>
                      )}
                      options={selectItems.map((item) => ({
                        label: item,
                        value: item,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="RIGHT ANSWER"
                    name="right_answer"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
                <Col span="24" className="mt_15">
                  <Button type="primary" size="large" className="w_100">
                    Save
                  </Button>
                </Col>
              </Row>
            </Drawer>

            {/* Edit Type Four Drawer */}
            <Drawer
              title="Edit Correct Image"
              width={580}
              onClose={onEditTypeFourClose}
              open={editTypeFourOpen}
              maskClosable={false}
            >
              <Row>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ENTER QUESTION SENTENCES"
                    name="question_sentences"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="UPLOAD"
                    name="upload"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Upload
                      action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                    {previewImage && (
                      <Image
                        wrapperStyle={{
                          display: "none",
                        }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) =>
                            !visible && setPreviewImage(""),
                        }}
                        src={previewImage}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="MATERIAL ACCESS"
                    name="material_access"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Checkbox defaultChecked={true}>Patient’s</Checkbox>
                    <Checkbox>Clinician’s</Checkbox>
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="CAN WE ADD AS A NEW MATERIAL?"
                    name="add_material"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Radio.Group onChange={onChangeRatio} value={value}>
                      <Radio value={1}>Yes</Radio>
                      <Radio value={2}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ENTER NEW MATERIAL VALUE IN SAR"
                    name="new_material"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="RIGHT ANSWER IMAGE"
                    name="right_answer_image"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    {/* <TextArea rows={4} /> */}
                  </Form.Item>
                </Col>
                <Col span="24" className="mt_15">
                  <Button type="primary" size="large" className="w_100">
                    Update
                  </Button>
                </Col>
              </Row>
            </Drawer>

            {/* Edit Type Five Drawer */}
            <Drawer
              title="Edit One Word Answer Based On 6 Images"
              width={580}
              onClose={onEditTypeFiveClose}
              open={editTypeFiveOpen}
              maskClosable={false}
            >
              <Row>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ENTER QUESTION SENTENCES"
                    name="question_sentences"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="UPLOAD"
                    name="upload"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Upload
                      action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                    {previewImage && (
                      <Image
                        wrapperStyle={{
                          display: "none",
                        }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) =>
                            !visible && setPreviewImage(""),
                        }}
                        src={previewImage}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="MATERIAL ACCESS"
                    name="material_access"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Checkbox defaultChecked={true}>Patient’s</Checkbox>
                    <Checkbox>Clinician’s</Checkbox>
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="CAN WE ADD AS A NEW MATERIAL?"
                    name="add_material"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Radio.Group onChange={onChangeRatio} value={value}>
                      <Radio value={1}>Yes</Radio>
                      <Radio value={2}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ENTER NEW MATERIAL VALUE IN SAR"
                    name="new_material"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="RIGHT ANSWER TEXT"
                    name="right_answer_text"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
                <Col span="24" className="mt_15">
                  <Button type="primary" size="large" className="w_100">
                    Update
                  </Button>
                </Col>
              </Row>
            </Drawer>

            {/* Edit Type Six Drawer */}
            <Drawer
              title="Write the Correct Sentence Based On 6 Images"
              width={580}
              onClose={onEditTypeSixClose}
              open={editTypeSixOpen}
              maskClosable={false}
            >
              <Row>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ENTER QUESTION SENTENCES"
                    name="question_sentences"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="UPLOAD"
                    name="upload"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Upload
                      action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                    {previewImage && (
                      <Image
                        wrapperStyle={{
                          display: "none",
                        }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) =>
                            !visible && setPreviewImage(""),
                        }}
                        src={previewImage}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="MATERIAL ACCESS"
                    name="material_access"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Checkbox defaultChecked={true}>Patient’s</Checkbox>
                    <Checkbox>Clinician’s</Checkbox>
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="CAN WE ADD AS A NEW MATERIAL?"
                    name="add_material"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Radio.Group onChange={onChangeRatio} value={value}>
                      <Radio value={1}>Yes</Radio>
                      <Radio value={2}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ENTER NEW MATERIAL VALUE IN SAR"
                    name="new_material"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="RIGHT ANSWER SENTENCE"
                    name="right_answer_sentence"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
                <Col span="24" className="mt_15">
                  <Button type="primary" size="large" className="w_100">
                    Update
                  </Button>
                </Col>
              </Row>
            </Drawer>

            {/* Edit Type Seven Drawer */}
            <Drawer
              title="Form The Sentence Based On 4 Images And Images Title"
              width={580}
              onClose={onEditTypeSevenClose}
              open={editTypeSevenOpen}
              maskClosable={false}
            >
              <Row>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ENTER QUESTION SENTENCES"
                    name="question_sentences"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="UPLOAD IMAGE 1"
                    name="upload"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Upload
                      action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                    {previewImage && (
                      <Image
                        wrapperStyle={{
                          display: "none",
                        }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) =>
                            !visible && setPreviewImage(""),
                        }}
                        src={previewImage}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="TITLE 1"
                    name="title_one"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="UPLOAD IMAGE 2"
                    name="upload_image_two"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Upload
                      action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                    {previewImage && (
                      <Image
                        wrapperStyle={{
                          display: "none",
                        }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) =>
                            !visible && setPreviewImage(""),
                        }}
                        src={previewImage}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="TITLE 2"
                    name="title_two"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="UPLOAD IMAGE 3"
                    name="upload_image_three"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Upload
                      action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                    {previewImage && (
                      <Image
                        wrapperStyle={{
                          display: "none",
                        }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) =>
                            !visible && setPreviewImage(""),
                        }}
                        src={previewImage}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="TITLE 3"
                    name="title_three"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="UPLOAD IMAGE 4"
                    name="upload_image_four"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Upload
                      action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                    >
                      {fileList.length >= 8 ? null : uploadButton}
                    </Upload>
                    {previewImage && (
                      <Image
                        wrapperStyle={{
                          display: "none",
                        }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) =>
                            !visible && setPreviewImage(""),
                        }}
                        src={previewImage}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="TITLE 4"
                    name="title_four"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="COMPLETE THE SENTENCE"
                    name="complete_the_sentence"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
                <Col span="24" className="mt_15">
                  <Button type="primary" size="large" className="w_100">
                    Update
                  </Button>
                </Col>
              </Row>
            </Drawer>
          </>
        );
      }}
    </Observer>
  );
};

export default Materials;
