import { Button, message, Popconfirm, Table } from "antd";
import React from "react";
import {
  LeftOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  PlusOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import Rating from "../common/field/Rating";

const FaqList = (props) => {
  const { ViewShowDrawer, setViewOpen, AddShowDrawer, setIsUpdating } = props;
  //^1.4.3
  //"scripts": {
  //   "start": "set PORT=3006 && react-scripts start",
  //   "build": "react-scripts build",
  //   "test": "react-scripts test",
  //   "eject": "react-scripts eject"
  // },
  //node-sass
  const userData = [
    {
      id: 1,
      date: "kishan@gmail.com",
      title: "Kishan",
      description: "This is the description",
    },
    {
      id: 2,
      date: "14/08/2024",
      title: "This is the title",
      description: "This is the description",
    },
    {
      id: 3,
      date: "14/08/2024",
      title: "This is the title",
      description: "This is the description",
    },
    {
      id: 4,
      date: "14/08/2024",
      title: "This is the title",
      description: "This is the description",
    },
    {
      id: 5,
      date: "14/08/2024",
      title: "This is the title",
      description: "This is the description",
    },
    
  ];
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      // render: (_, record) => <Rating />,
    },

    {
      title: "Action",
      render: (_, record) => (
        <>
          <div className="dashboard_action">
            <Button
              className="action_btn view mr_5"
              onClick={() => {
                ViewShowDrawer(record.id, [() => setViewOpen(true)]);
              }}
            >
              <EyeOutlined />
            </Button>
            <Button
              className="action_btn edit mr_5"
              onClick={() => {
                AddShowDrawer(() => setIsUpdating(true), record._id)
              }}
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              title="Delete the task"
              description="Are you sure to delete this task?"
              onConfirm={() => {
                // confirm(record._id)
              }}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button className="action_btn delete mr_5">
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </div>
        </>
      ),
    },
  ];

  const cancel = (e) => {
    console.log(e);
    message.error("Click on No");
  };

  return (
    <Table
      rowKey={(data) => data?._id ?? 0}
      columns={columns}
      dataSource={userData}
      scroll={{
        x: 800,
      }}
    />
  );
};

export default FaqList;
