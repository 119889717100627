import { Button, Col, Input, Row } from "antd";
import React, { useState } from "react";
import {
  LeftOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import FeedBackList from "./FeedBackList";
import FeedbackDetail from "./FeedbackDetail";
import FeedBackForm from "./FeedBackForm";

const Index = () => {
  const [viewOpen, setViewOpen] = useState(false);
  const [addFeedbackViewOpen, setAddFeedbackViewOpen] = useState(false);

  const onViewClose = () => {
    setViewOpen(false);
  };

  const ViewShowDrawer = (subId, callBack) => {
    // const data = subscriptionStore.subscriptionData.find(
    //   (data) => data._id === subId
    // );

    // console.log("data", toJS(data));

    // clinicSubscriptionForm.setFieldsValue({
    //   planName: data.title,
    //   durationPeriod: data.durationPeriod,
    //   durationIn: data.durationIn,
    //   subscriptionFor: data.userType,
    //   features: data.description,
    //   cost: data.amount,
    //   status: data.status ? "1" : "0",
    // });
    // subscriptionStore.setSingleSubscriptionData(data);
    // for (let index = 0; index < callBack.length; index++) {
    //   const element = callBack[index];
    //   element();
    // }
    // callBack();
    setViewOpen(true);
  };

  const toggleFeedbackFom = () => setAddFeedbackViewOpen(!addFeedbackViewOpen);

  return (
    <>
      <Row>
        <Col span={24}>
          <div className="dashboard_tops">
            <Row>
              <Col span={4}>
                <div className="title">FeedBacks</div>
              </Col>
              <Col span={20}>
                <Row justify={"end"} gutter={5}>
                  <Col span={6}>
                    <Input
                      size="large"
                      placeholder="Search"
                      prefix={<SearchOutlined />}
                      className="mr_5"
                      onChange={(e) => {
                        // usersStore.setUserSearchQuery(e.target.value)
                      }}
                      style={{ width: "100%" }}
                    />
                  </Col>
                  {/* <Col span={3}>
                    <Button
                      type="primary"
                      size="large"
                      block
                      icon={<PlusOutlined />}
                      onClick={() => {
                        toggleFeedbackFom()
                        // setAddClinicianDrawerOpen(true);
                      }}
                    >
                      Add New
                    </Button>
                  </Col> */}
                  {/* <Col span={3}>
                  <Button
                    type="primary"
                    size="large"
                    block
                    icon={<PlusOutlined />}
                    onClick={() =>
                      AddShowDrawer(() => setIsUpdatingUser(false), null)
                    }
                  >
                    Add New
                  </Button>
                </Col> */}
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <FeedBackList
        onViewClose={onViewClose}
        viewOpen={viewOpen}
        ViewShowDrawer={ViewShowDrawer}
      />
      <FeedbackDetail onViewClose={onViewClose} viewOpen={viewOpen} />
      <FeedBackForm
        onViewClose={toggleFeedbackFom}
        viewOpen={addFeedbackViewOpen}
      />
    </>
  );
};

export default Index;
