import { makeAutoObservable } from "mobx";
import LoginStore from "./loginStore";
import { createContext, useContext } from "react";
import DashboardStore from "./dashboardStore";
import SubscriptionStore from "./subscriptionsStore";
import UsersStore from "./users.store";
import GlobalStore from "./global.store";
import DisorderStore from "./disorer.store";
import ActivityStore from "./activity.store";

class MainStore {

    loginStore = {}
    dashboardStore = {}
    subscriptionStore = {}
    usersStore = {}
    globalStore = {}
    disorderStore = {}
    activityStore={}

    constructor() {
        makeAutoObservable(this)

        this.loginStore = new LoginStore(this)
        this.dashboardStore = new DashboardStore()
        this.subscriptionStore = new SubscriptionStore()
        this.usersStore = new UsersStore()
        this.globalStore = new GlobalStore()
        this.disorderStore = new DisorderStore()
        this.activityStore = new ActivityStore()
    }

}

export const mainStore = new MainStore()

const StoreContext = createContext({});
export const StoreProvider = StoreContext.Provider;

export const useMainStore = () => useContext(StoreContext);