import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  Radio,
  Row,
  Upload,
  message,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Observer } from "mobx-react";
import { useMainStore } from "../../store/mainStore";
import { useUploadMaterial } from "../../hooks/useUploadMaterial";

const AddNewDocumentDrawer = () => {
  const mainStore = useMainStore();
  const { disorderStore } = mainStore;

  const [addDocumentMaterialForm] = Form.useForm();

  const [disabled, setDisabled] = React.useState(false);

  const { handleMaterialUpload, base64, loading, fileObject } =
    useUploadMaterial();

  const handleAddDocument = async (values) => {
    console.log(values);

    const {
      singleDisorderData,
      addNewVideoMaterial,
      setIsAddingMaterial,
      setAddDocumentMaterialDrawerStatus,
      getMaterial,
    } = disorderStore;

    try {
      const [{ _id }] = singleDisorderData;
      setIsAddingMaterial(true);

      const formData = new FormData();

      formData.append("disorderId", _id);
      formData.append("name", values.name);
      formData.append("description", values.description);
      formData.append("materialType", "DOCUMENT");
      formData.append("access", values.access.join(","));
      formData.append("isNewMaterial", values.isNewMaterial ? true : false);
      formData.append("materialValue", values.materialValue);
      formData.append("uploadedFile", fileObject);
      formData.append("status", true);

      const { success, data } = await addNewVideoMaterial(formData);

      if (!success) {
        setIsAddingMaterial();
        addDocumentMaterialForm.resetFields();
        return message.error("Something went wrong");
      }

      getMaterial({
        disorderId: _id,
        materialType: "DOCUMENT",
      });

      setIsAddingMaterial();
      addDocumentMaterialForm.resetFields();
      setAddDocumentMaterialDrawerStatus();
      message.success("Material added successfully");
    } catch (error) {
      setIsAddingMaterial();
      addDocumentMaterialForm.resetFields();
      message.error("Something went wrong");
    }
  };

  return (
    <Observer>
      {() => {
        const {
          setAddDocumentMaterialDrawerStatus,
          addDocumentMaterialDrawerStatus,
          isAddingMaterial,
        } = disorderStore;
        return (
          <Drawer
            title="Create a Document Material"
            width={500}
            onClose={() => setAddDocumentMaterialDrawerStatus()}
            open={addDocumentMaterialDrawerStatus}
            maskClosable={false}
          >
            <Form onFinish={handleAddDocument} form={addDocumentMaterialForm} layout="vertical">
              <Row>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="DOCUMENT NAME"
                    name="name"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                    rules={[{ required: true, message: "Please enter a document name" }]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="DESCRIPTION"
                    name="description"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                    rules={[{ required: true, message: "Please enter a description" }]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="MATERIAL ACCESS"
                    name="access"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                    rules={[{ required: true, message: "Please select at least one option" }]}
                  >
                    <Checkbox.Group
                      options={[
                        {
                          label: "PATIENT",
                          value: "PATIENT",
                        },
                        {
                          label: "CLINIC",
                          value: "CLINIC",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="CAN WE ADD AS A NEW MATERIAL?"
                    name="isNewMaterial"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                    rules={[{ required: true, message: "Please select an option" }]} 
                  >
                    <Radio.Group onChange={(e) => setDisabled(e.target.value) } >
                      <Radio value={1}>Yes</Radio>
                      <Radio value={0}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="ENTER NEW MATERIAL VALUE IN SAR"
                    name="materialValue"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                    rules={[
                      { required: disabled, message: "Please enter the material value" },
                    ]}
                  >
                    <Input size="large" disabled={!disabled} onKeyDown={(event) => {
                    if (!/[0-9]/.test(event.key) && event.key !== "Backspace") {
                      event.preventDefault();
                    }
                  }} />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    layout="vertical"
                    label="UPLOAD DOCUMENT"
                    name="uploadedFile"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    className="mb_10"
                    rules={[
                      { required: true, message: "Please upload a document" },
                    ]}
                  >
                    <Upload
                      customRequest={() => {}}
                      fileList={[]}
                      onChange={handleMaterialUpload}
                      className="document_upload"
                    >
                      <Button icon={<UploadOutlined />}>Upload</Button>
                    </Upload>
                  </Form.Item>
                </Col>
                <Col span="24" className="mt_15">
                  <Button
                    loading={isAddingMaterial}
                    type="primary"
                    htmlType="submit"
                    size="large"
                    className="w_100"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Drawer>
        );
      }}
    </Observer>
  );
};

export default AddNewDocumentDrawer;
