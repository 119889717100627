import { Drawer, Row } from "antd";
import React from "react";

const FaqDetail = (props) => {
  const { onViewClose, viewOpen } = props;
  return (
    <Drawer
      title="FAQ Details"
      width={500}
      onClose={onViewClose}
      open={viewOpen}
    >
      <Row>
        {/* <div className="subscription_details">
          <div className="label">DATE</div>
          <div className="value">13/04/2024</div>
        </div> */}
        <div className="subscription_details">
          <div className="label">TITLE</div>
          <div className="value">Test title</div>
        </div>
        <div className="subscription_details">
          <div className="label">DESCRIPTION</div>
          <div className="value">Test Description</div>
        </div>
      </Row>
    </Drawer>
  );
};

export default FaqDetail;
