import { Button, Col, Input, Row } from "antd";
import React, { useState } from "react";
import {
  LeftOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import FaqList from "./FaqList";
import FaqDetail from "./FaqDetail";
import FaqForm from "./FaqForm";

const Index = () => {
  const [viewOpen, setViewOpen] = useState(false);
  const [addFAQViewOpen, setAddFAQViewOpen] = useState(false);
  const [isAddOpen, setAddOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const onViewClose = () => {
    setViewOpen(false);
  };

  const ViewShowDrawer = (subId, callBack) => {
    // const data = subscriptionStore.subscriptionData.find(
    //   (data) => data._id === subId
    // );

    // console.log("data", toJS(data));

    // clinicSubscriptionForm.setFieldsValue({
    //   planName: data.title,
    //   durationPeriod: data.durationPeriod,
    //   durationIn: data.durationIn,
    //   subscriptionFor: data.userType,
    //   features: data.description,
    //   cost: data.amount,
    //   status: data.status ? "1" : "0",
    // });
    // subscriptionStore.setSingleSubscriptionData(data);
    // for (let index = 0; index < callBack.length; index++) {
    //   const element = callBack[index];
    //   element();
    // }
    // callBack();
    setViewOpen(true);
  };

  const AddShowDrawer = (callBack, userId) => {
    // if (userId) {
    //   const data = usersStore.usersList.find((data) => data._id === userId);
    //   console.log("data", toJS(data));

    //   usersStore.setSingleUserData(data);
    //   addNewUserFormRef.setFieldsValue({
    //     name: data.name,
    //     email: data.email,
    //     username: data.username,
    //     contactNumber: data.mobileNumber,
    //     country: data.countryName,
    //     region: data.regionName,
    //     status: data.status ? "1" : "0",
    //     userType: data.userType,
    //   });
    // }

    // addNewUserFormRef.resetFields();
    setAddOpen(true);

    callBack();
  };

  const toggleViewFAQFom = () => setAddFAQViewOpen(!addFAQViewOpen);
  const toggleAddFAQFom = () => setAddOpen(!isAddOpen);

  return (
    <>
      <Row>
        <Col span={24}>
          <div className="dashboard_tops">
            <Row>
              <Col span={4}>
                <div className="title">FAQ</div>
              </Col>
              <Col span={20}>
                <Row justify={"end"} gutter={5}>
                  <Col span={6}>
                    <Input
                      size="large"
                      placeholder="Search"
                      prefix={<SearchOutlined />}
                      className="mr_5"
                      onChange={(e) => {
                        // usersStore.setUserSearchQuery(e.target.value)
                      }}
                      style={{ width: "100%" }}
                    />
                  </Col>
                  <Col span={3}>
                    <Button
                      type="primary"
                      size="large"
                      block
                      icon={<PlusOutlined />}
                      onClick={() => {
                        toggleAddFAQFom()
                        // setAddClinicianDrawerOpen(true);
                      }}
                    >
                      Add New
                    </Button>
                  </Col>
                  {/* <Col span={3}>
                  <Button
                    type="primary"
                    size="large"
                    block
                    icon={<PlusOutlined />}
                    onClick={() =>
                      AddShowDrawer(() => setIsUpdatingUser(false), null)
                    }
                  >
                    Add New
                  </Button>
                </Col> */}
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <FaqList
        onViewClose={onViewClose}
        viewOpen={viewOpen}
        ViewShowDrawer={ViewShowDrawer}
        AddShowDrawer={AddShowDrawer}
        setIsUpdating={setIsUpdating}
      />
      <FaqDetail onViewClose={onViewClose} viewOpen={viewOpen} />
      <FaqForm
        onViewClose={toggleAddFAQFom}
        viewOpen={isAddOpen}
      />
    </>
  );
};

export default Index;
