import { Button, Col, Drawer, Form, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import TextEditor from "../common/field/TextEditor";

const FeedbackDetail = (props) => {
  const { onViewClose, viewOpen } = props;
  return (
    <Drawer
      title="Feedback Details"
      width={500}
      onClose={onViewClose}
      open={viewOpen}
    >
      <Row>
        <div className="subscription_details">
          <div className="label">NAME</div>
          <div className="value">Kishan</div>
        </div>
        <div className="subscription_details">
          <div className="label">Email Id</div>
          <div className="value">kishan@gmail.com</div>
        </div>
        <div className="subscription_details">
          <div className="label">Date</div>
          <div className="value">12/09/24</div>
        </div>
        <div className="subscription_details">
          <div className="label">Rating</div>
          <div className="value">kishan@gmail.com</div>
        </div>
        <div className="subscription_details">
          <div className="label">Patient Feedback</div>
          <div className="value">Hello this is the feedback</div>
        </div>
        <div className="mt_10 w_100" style={{}}>
          <Form
            onFinish={() => {}}
            layout="vertical"
            // initialValues={
            //   {
            //     name: usersStore.singleUserData?.name,
            //     email: usersStore.singleUserData?.email,
            //     contactNumber: usersStore.singleUserData?.mobileNumber,
            //     country: usersStore.singleUserData?.countryId,
            //     region: usersStore.singleUserData?.regionId,
            //     userType: usersStore.singleUserData?.userType,
            //     status: usersStore.singleUserData?.isDeleted ? "1" : "1",
            //     userType: usersStore.singleUserData?.userType || "PATIENT"
            //   }
            // }
            // form={addNewUserFormRef}
          >
            <Row>
              <Col span="24" style={{height:"200px"}}>
                <Form.Item
                  layout="vertical"
                  label="DESCRIPTION"
                  name="description"
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  className="mb_10"
                  rules={[
                    {
                      required: true,
                      message: "Please enter description!",
                    },
                  ]}
                >
                  {/* <TextArea style={{width:"100%"}} rows={4} /> */}
                  <TextEditor style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span="24" className="mt_10" style={{display:"flex", justifyContent:"right"}}>
                <Button type="primary" size="large" htmlType="submit">
                  {"REPLY"}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Row>
    </Drawer>
  );
};

export default FeedbackDetail;
