import { Link, useNavigate } from "react-router-dom";
import { Content, Header } from "antd/es/layout/layout";
import {
  Button,
  Col,
  Form,
  Drawer,
  Image,
  Input,
  Layout,
  Menu,
  Row,
  Table,
  Select,
  Dropdown,
  Avatar,
  Popconfirm,
  message,
} from "antd";
import Logo from "../assets/logo.png";
import AvatarImg from "../assets/avatar.svg";
import Sider from "antd/es/layout/Sider";
import {
  BiSolidUserAccount,
  BiSolidDollarCircle,
  BiSolidCloudUpload,
  BiSolidLike,
  BiSolidMessageSquareError,
} from "react-icons/bi";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  LeftOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import { observer } from "mobx-react";
import { useMainStore } from "../store/mainStore";
import { sendNotification } from "../utils/utilities";

const SubscriptionsTwo = () => {
  const mainStore = useMainStore();
  const { subscriptionStore } = mainStore;

  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(false);

  // Add Drawer
  const [addOpen, setAddOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [patientSubscriptionForm] = Form.useForm();

  useEffect(() => {
    subscriptionStore.getAllSubscriptions({
      userType: "PATIENT", //CLINIC
    });
  }, []);

  const AddShowDrawer = () => {
    setAddOpen(true);
  };
  const onAddClose = () => {
    setAddOpen(false);
    setIsUpdating(false);
  };

  // View Drawer
  const [viewOpen, setViewOpen] = useState(false);
  const ViewShowDrawer = (subId, callBack) => {
    const data = subscriptionStore.subscriptionData.find(
      (data) => data._id === subId
    );

    patientSubscriptionForm.setFieldsValue({
      planName: data.title,
      durationPeriod: data.durationPeriod,
      durationIn: data.durationIn,
      subscriptionFor: data.userType,
      features: data.description,
      cost: data.amount,
      status: data.status ? "1" : "0",
    });

    subscriptionStore.setSingleSubscriptionData(data);
    for (let index = 0; index < callBack.length; index++) {
      const element = callBack[index];
      element();
    }
    // callBack();
    // setViewOpen(true);
  };
  const onViewClose = () => {
    setViewOpen(false);
  };

  const columns = [
    {
      title: "Plan Name",
      dataIndex: "title",
    },
    {
      title: "Duration",
      dataIndex: "durationIn",
    },
    {
      title: "Features",
      dataIndex: "description",
    },
    {
      title: "Cost",
      dataIndex: "amount",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, data) => {
        return (
          <div className="dashboard_action">
            <Button className="active_btn mr_5">
              {data.status ? "Active" : "Deactive"}
            </Button>
          </div>
        );
      },
    },
    {
      title: "Action",
      render: (_, record) => (
        <>
          <div className="dashboard_action">
            <Button
              className="action_btn view mr_5"
              onClick={() =>
                ViewShowDrawer(record._id, [() => setViewOpen(true)])
              }
            >
              <EyeOutlined />
            </Button>
            <Button
              className="action_btn edit mr_5"
              onClick={() =>
                ViewShowDrawer(record._id, [
                  () => AddShowDrawer(true),
                  () => setIsUpdating(true),
                ])
              }
            >
              <EditOutlined />
            </Button>
            <Popconfirm
              title="Delete the task"
              description="Are you sure to delete this task?"
              onConfirm={() => confirm(record._id)}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button className="action_btn delete mr_5">
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </div>
        </>
      ),
    },
  ];
  // const data = [
  //   {
  //     key: "1",
  //     plan_name: "Silver",
  //     duration: "1 Month",
  //     features: "Access to reserved parking spots.",
  //     cost: "500 AED",
  //     status: (
  //       <div className="dashboard_action">
  //         <Button className="active_btn mr_5">Active</Button>
  //       </div>
  //     ),
  //   },
  //   {
  //     key: "2",
  //     plan_name: "Platinum",
  //     duration: "6 Month",
  //     features: "Access to reserved parking spots.",
  //     cost: "2000 AED",
  //     status: (
  //       <div className="dashboard_action">
  //         <Button className="active_btn mr_5">Active</Button>
  //       </div>
  //     ),
  //   },
  //   {
  //     key: "3",
  //     plan_name: "Gold",
  //     duration: "1 Year",
  //     features: "Access to reserved parking spots.",
  //     cost: "4000 AED",
  //     status: (
  //       <div className="dashboard_action">
  //         <Button className="active_btn mr_5">Active</Button>
  //       </div>
  //     ),
  //   },
  // ];

  const confirm = async (subId) => {
    await subscriptionStore.deleteSubscriptions({
      subscriptionId: subId,
    });

    await subscriptionStore.getAllSubscriptions({
      userType: "PATIENT", //CLINIC
    });
  };
  const cancel = (e) => {
    console.log(e);
    message.error("Click on No");
  };

  const items = [
    {
      key: "1",
      label: <Link to="/forgot-password">Forgot Password</Link>,
    },
    {
      key: "2",
      label: <Link to={"/logout"}>Logout</Link>,
    },
  ];

  const handleAddSubscription = async (values) => {
    if (!isUpdating) {
      const { addNewSubscription } = subscriptionStore;

      const { success, data } = await addNewSubscription({
        ...values,
        subscriptionFor: "PATIENT",
      });

      if (success) {
        sendNotification({ notificationType: "success", message: data });
        subscriptionStore.getAllSubscriptions({
          userType: "PATIENT", //CLINIC
        });
        onAddClose();
        return;
      }

      sendNotification({
        notificationType: "error",
        message: "Something went wrong",
      });
    } else {
      console.log("values", values);
      const { updateSubscriptions, singleSubscriptionData } = subscriptionStore;

      const newBody = {
        ...values,
        subscriptionId: singleSubscriptionData._id,
      };
      const { success, data } = await updateSubscriptions({
        ...newBody,
        subscriptionFor: "PATIENT",
      });

      if (success) {
        sendNotification({ notificationType: "success", message: data });
        subscriptionStore.getAllSubscriptions({
          userType: "PATIENT", //CLINIC
        });
        setIsUpdating(false);
        onAddClose();
        return;
      }

      setIsUpdating(false);

      sendNotification({
        notificationType: "error",
        message: "Something went wrong",
      });
    }
  };

  return (
    <>
      <Layout style={{ width: "100%", height: "100%" }}>
        <Sider
          style={{
            backgroundColor: "#35A8DF",
            height: "auto",
            // borderRadius: 16,
            marginTop: -10,
            marginLeft: -10,
            marginBottom: -10,
          }}
          trigger={null}
          collapsible
          collapsed={collapsed}
        >
          {!collapsed ? (
            <Image
              width={180}
              src={Logo}
              alt="Logo"
              preview={false}
              style={{
                margin: 10,
                padding: 10,
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          ) : (
            <Image
              width={70}
              src={Logo}
              alt="Logo"
              preview={false}
              style={{
                margin: 10,
                padding: 10,
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          )}
          <Menu
            style={{
              // backgroundColor: "#35A8DF",
              // color: "#fff",
              height: "100vh",
            }}
            mode="inline"
            theme="dark"
            defaultSelectedKeys={["/caretaker-parents-consumer"]}
            onClick={(item) => {
              navigate(item.key);
            }}
            items={[
              // {
              //   key: "/dashboard",
              //   icon: <BiHomeAlt2 />,
              //   label: "Dashboard",
              // },
              // {
              //   key: "/materials",
              //   icon: <FaUserSecret />,
              //   label: "Materials / Clinical resources management",
              // },

              {
                key: "/users",
                icon: <BiSolidUserAccount />,
                label: "User management",
              },
              {
                key: "subscription",
                label: "Subscription",
                icon: <BiSolidDollarCircle />,
                children: [
                  {
                    key: "/slp-user-clinicians-subscription",
                    label: "Clinician",
                  },
                  {
                    key: "/caretaker-parents-consumer",
                    label: "Patient",
                  },
                ],
              },
              {
                key: "/materials",
                icon: <BiSolidCloudUpload />,
                label: "Materials Management",
              },
              {
                key: "/faq",
                icon: <BiSolidMessageSquareError />,
                label: "FAQ",
              },
              {
                key: "/feedbacks",
                icon: <BiSolidLike />,
                label: "Feedbacks",
              },
              
              // {
              //   key: "/reports",
              //   icon: <FaUsers />,
              //   label: "Reports",
              // },
              // {
              //   key: "/kpiconfig",
              //   icon: <RiCustomerService2Line />,
              //   label: "KPI configuration",
              // },
              // {
              //   key: "/settings",
              //   icon: <FaTruckFast />,
              //   label: "Profile Settings",
              // },
            ]}
          />
        </Sider>
        <Layout>
          <Header
            style={{
              padding: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              backgroundColor: "#fff",
            }}
          >
            <Button
              type="text"
              icon={collapsed ? <GiHamburgerMenu /> : <LeftOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />
            <div>
              <Dropdown
                menu={{
                  items,
                }}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Avatar
                    src={<img src={AvatarImg} alt="avatar" />}
                    shape="square"
                    size="large"
                  />
                </a>
              </Dropdown>
            </div>
          </Header>
          <Content
            style={{
              backgroundColor: "#F1F5FF",
              padding: 20,
            }}
          >
            <Row>
              <Col span={24}>
                <div className="dashboard_top">
                  <div className="title">Patient Subscription</div>
                  <div className="add_data_dashboard">
                    {/* <Input
                      size="large"
                      placeholder="Search"
                      prefix={<SearchOutlined />}
                      className="mr_5"
                    /> */}
                    <Button
                      type="primary"
                      size="large"
                      icon={<PlusOutlined />}
                      onClick={AddShowDrawer}
                    >
                      Add New
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <Table
              columns={columns}
              dataSource={subscriptionStore.subscriptionData}
            />
          </Content>
        </Layout>
      </Layout>
      <Drawer
        title={!isUpdating ? "Add Subscription Plan" : "Edit Subscription Plan"}
        width={500}
        onClose={onAddClose}
        open={addOpen}
      >
        <Form
          form={patientSubscriptionForm}
          onFinish={handleAddSubscription}
          initialValues={{
            subscriptionFor: "PATIENT",
          }}
          layout="vertical"
        >
          <Row>
            <Col span="24">
              <Form.Item
                layout="vertical"
                label="PLAN NAME"
                name="planName"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                className="mb_10"
                rules={[{ required: true, message: "Please enter plan name" }]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col span="24">
              <Form.Item
                layout="vertical"
                label="DURATION PERIOD"
                name="durationPeriod"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                className="mb_10"
                rules={[
                  { required: true, message: "Please select duration period" },
                ]}
              >
                <Select
                  size="large"
                  style={{
                    width: "100%",
                  }}
                  options={[
                    {
                      value: "DAY",
                      label: "DAY",
                    },
                    {
                      value: "WEEK",
                      label: "WEEK",
                    },
                    {
                      value: "MONTH",
                      label: "MONTH",
                    },
                    {
                      value: "YEAR",
                      label: "YEAR",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span="24">
              <Form.Item
                layout="vertical"
                label="DURATION IN"
                name="durationIn"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                className="mb_10"
                rules={[
                  {
                    required: true,
                    message: "Please input duration in",
                  },
                  // {
                  //   pattern: /^[0-9]*$/,
                  //   message: 'Please input numbers only',
                  // },
                ]}
              >
                <Input
                  onKeyDown={(event) => {
                    if (!/[0-9]/.test(event.key) && event.key !== "Backspace") {
                      event.preventDefault();
                    }
                  }}
                  size="large"
                />
              </Form.Item>
            </Col>
            {/* <Col span="24">
              <Form.Item
                layout="vertical"
                label="SUBSCRIPTION FOR"
                name="subscriptionFor"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                className="mb_10"
              >
                <Select
                  size="large"
                  style={{
                    width: "100%",
                  }}
                  options={[
                    {
                      value: "PATIENT",
                      label: "Patients",
                    },
                  ]}
                />
              </Form.Item>
            </Col> */}
            <Col span="24">
              <Form.Item
                layout="vertical"
                label="FEATURES"
                name="features"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                className="mb_10"
                rules={[
                  {
                    required: true,
                    message: "Please input features",
                  },
                ]}
              >
                <TextArea rows={3} />
              </Form.Item>
            </Col>
            <Col span="24">
              <Form.Item
                layout="vertical"
                label="COST IN SAR"
                name="cost"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                rules={[
                  {
                    required: true,
                    message: "Please input cost",
                  },
                ]}
              >
                <Input
                  // onChange={(event) => {
                  //   const { value } = event.target;
                  //   const numericValue = value.replace(/[^0-9]/g, '');
                  //   event.target.value = numericValue;
                  // }}
                  size="large"
                  onKeyDown={(event) => {
                    if (!/[0-9]/.test(event.key) && event.key !== "Backspace") {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            {isUpdating ? (
              <Col span="24">
                <Form.Item
                  layout="vertical"
                  label="STATUS"
                  name="status"
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  className="mb_10"
                  rules={[
                    {
                      required: true,
                      message: "Please select status",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    style={{
                      width: "100%",
                    }}
                    options={[
                      {
                        value: "1",
                        label: "Active",
                      },
                      {
                        value: "0",
                        label: "Deactive",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
            ) : (
              <></>
            )}
            <Col span="24" className="text_right">
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                block
                className="w-100"
              >
                {!isUpdating ? "Save" : "Update"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Drawer>
      <Drawer
        title="Subscription Details"
        width={500}
        onClose={onViewClose}
        open={viewOpen}
      >
        <Row>
          <div className="subscription_details">
            <div className="label">PLAN NAME</div>
            <div className="value">
              {subscriptionStore.singleSubscriptionData.title}
            </div>
          </div>
          <div className="subscription_details">
            <div className="label">DURATION PERIOD</div>
            <div className="value">
              {subscriptionStore.singleSubscriptionData.durationIn}{" "}
              {subscriptionStore.singleSubscriptionData.durationPeriod}
            </div>
          </div>
          {/* <div className="subscription_details">
            <div className="label">SUBSCRIPTION FOR </div>
            <div className="value">Patients</div>
          </div> */}
          <div className="subscription_details">
            <div className="label">FEATURES</div>
            <div className="list">
              <ul>
                <li>{subscriptionStore.singleSubscriptionData.description}</li>
              </ul>
            </div>
          </div>
          <div className="subscription_details">
            <div className="label">COST IN SAR</div>
            <div className="value">
              {subscriptionStore.singleSubscriptionData.amount} SAR
            </div>
          </div>
        </Row>
      </Drawer>
    </>
  );
};

export default observer(SubscriptionsTwo);
