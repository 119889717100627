import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ConfigProvider } from "antd";
import Login from "./pages/Login";
import Protected from "./components/Protected";
import Materials from "./pages/Materials";
import Subscriptions from "./pages/Subscriptions";
import Users from "./pages/Users";
import ActivityTaskManage from "./pages/ActivityTaskManage";
import Reports from "./pages/Reports";
import KpiConfig from "./pages/KpiConfig";
import ProfileSettings from "./pages/ProfileSettings";
import EditSubscription from "./pages/EditSubscription";
import "./styles/main.scss";
import SubscriptionsOne from "./pages/SubscriptionsOne";
import SubscriptionsTwo from "./pages/SubscriptionsTwo";
import ForgotPassword from "./pages/ForgotPassword";
import ChangePassword from "./pages/change-password";
import { StoreProvider, mainStore } from "./store/mainStore";
import Logout from "./pages/Logout";
import FeedBacks from "./components/feedbacks/Index";
import FAQ from "./components/faq/Index";
import AuthLayout from "./components/layoutes/AuthLayout";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <StoreProvider value={mainStore}>
      <BrowserRouter>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#35A8DF",
            },
          }}
        >
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/dashboard" element={<App />} />
            <Route
              path="/slp-user-clinicians-subscription"
              element={<Protected Component={SubscriptionsOne} />}
            />
            <Route
              path="/caretaker-parents-consumer"
              element={<Protected Component={SubscriptionsTwo} />}
            />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/change-password" element={<ChangePassword />} />
            {/* <Protected Component={App} /> */}
            <Route element={<AuthLayout />}>
              <Route path="/faq" element={<Protected Component={FAQ} />} />
              <Route
                path="/feedbacks"
                element={<Protected Component={FeedBacks} />}
              />
            </Route>

            <Route
              path="/materials"
              element={<Protected Component={Materials} />}
            />

            <Route
              path="/subscriptions"
              element={<Protected Component={Subscriptions} />}
            />
            <Route
              path="/editSubscription"
              element={<Protected Component={EditSubscription} />}
            />
            {/* <Route path="/users" element={<Protected Component={Users} />} /> */}
            <Route path="/users" element={<Protected Component={Users} />} />
            <Route
              path="/activity"
              element={<Protected Component={ActivityTaskManage} />}
            />
            <Route
              path="/reports"
              element={<Protected Component={Reports} />}
            />
            <Route
              path="/kpiconfig"
              element={<Protected Component={KpiConfig} />}
            />
            <Route
              path="/settings"
              element={<Protected Component={ProfileSettings} />}
            />
          </Routes>
        </ConfigProvider>
      </BrowserRouter>
    </StoreProvider>
  </React.StrictMode>
);
