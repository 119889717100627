import { makeAutoObservable, runInAction } from "mobx";
import { ADD_ACTIVITY, UPDATE_ACTIVITY } from "../constants/apiConstants";
import { postRequest } from "../utils/api";

class ActivityStore {
  materialId = "";
  constructor() { 
    makeAutoObservable(this);
  }

  addNewActivity = async (body) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { response, error } = await postRequest(ADD_ACTIVITY, body);
        if (!error && response.success) {
          console.log("response-data : ", response.data);
          this.setMaterialID(response.data.id ? response.data.id : "")
          return resolve({ success: true, data: response.data });
        }
        return reject({ success: false, data: [] });
      } catch (error) {
        return reject({ success: false, data: [] });
      }
    });
  };

  updateActivity = async (body) => {
    console.log(body, "update");
    return new Promise(async (resolve, reject) => {
      try {
        const { response, error } = await postRequest(UPDATE_ACTIVITY, body);
        console.log(response, 'response from ')
        if (!error && response.success) {
          return resolve({ success: true, data: response.data });
        }
        return reject({ success: false, data: [] });
      } catch (error) {
        return reject({ success: false, data: [] });
      }
    });
  };

  setMaterialID = (data = "") => {
    runInAction(() => {
      this.materialId = data;
    });
  };
}

export default ActivityStore;
